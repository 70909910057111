export const GAME_TITLE = 'Parolle'

export const WIN_MESSAGES = ['Ottimo lavoro!', 'Grandioso', 'Ben fatto!']
export const GAME_COPIED_MESSAGE = 'Parolle copiate negli appunti'
export const ABOUT_GAME_MESSAGE = 'Info su questo gioco'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Non ci sono abbastanza lettere'
export const WORD_NOT_FOUND_MESSAGE = 'Parola non trovata'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `La parola era ${solution}`
export const ENTER_TEXT = 'Invio'
export const DELETE_TEXT = 'Elimina'
export const STATISTICS_TITLE = 'Statistiche'
export const GUESS_DISTRIBUTION_TEXT = 'Distribuzione dei tentativi'
export const NEW_WORD_TEXT = 'Nuova parola tra'
export const SHARE_TEXT = 'Condividi'
export const TOTAL_TRIES_TEXT = 'Tentativi totali'
export const SUCCESS_RATE_TEXT = 'Tasso di successo'
export const CURRENT_STREAK_TEXT = 'Vinte di fila'
export const BEST_STREAK_TEXT = 'Record di vittorie di fila'
export const EASY_MODE_MESSAGE = 'Modalità facile'
export const HARD_MODE_MESSAGE =
  'Modalità difficile: le lettere note DEVONO essere usate'
