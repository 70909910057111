import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Versione italiana di Wordle.
        <br />
        <a
          href="https://alfoadd.com"
          target="_blank"
          rel="noreferrer"
          className="underline font-bold"
        >
          Alfonso Adduci
        </a>
      </p>
    </BaseModal>
  )
}
