export const VALID_GUESSES = [
  'abaco',
  'abata',
  'abato',
  'abavi',
  'abavo',
  'abaya',
  'abbui',
  'abita',
  'abuna',
  'acari',
  'acaro',
  'acati',
  'acato',
  'acazi',
  'accia',
  'accio',
  'aceri',
  'acero',
  'acese',
  'acesi',
  'aceta',
  'achea',
  'achee',
  'achei',
  'acheo',
  'acile',
  'acili',
  'acini',
  'acino',
  'acori',
  'acoro',
  'acqui',
  'acquo',
  'actea',
  'actee',
  'acuii',
  'acume',
  'acumi',
  'adale',
  'adali',
  'addai',
  'addua',
  'addui',
  'adduo',
  'adesa',
  'adese',
  'adesi',
  'adeso',
  'adima',
  'adimi',
  'adimo',
  'adipe',
  'adipi',
  'adire',
  'adita',
  'adite',
  'adiva',
  'adivi',
  'adivo',
  'adobe',
  'adona',
  'adone',
  'adoni',
  'adono',
  'adoxa',
  'adoxe',
  'adusa',
  'aduse',
  'adusi',
  'aduso',
  'aequo',
  'aerai',
  'afaca',
  'afata',
  'afate',
  'afati',
  'afato',
  'afeli',
  'afeta',
  'afeti',
  'afide',
  'afidi',
  'afnii',
  'afnio',
  'agama',
  'agame',
  'agami',
  'agamo',
  'agape',
  'agapi',
  'agata',
  'agate',
  'agave',
  'agavi',
  'aggio',
  'agiai',
  'agino',
  'agite',
  'agiva',
  'agivi',
  'agivo',
  'agone',
  'agoni',
  'agora',
  'aguti',
  'aiace',
  'aiaci',
  'aiata',
  'aiate',
  'aiola',
  'aiole',
  'aioli',
  'aiolo',
  'aione',
  'aioni',
  'aitai',
  'aiuga',
  'aizza',
  'aizzi',
  'aizzo',
  'alaci',
  'alala',
  'alale',
  'alali',
  'alalo',
  'alami',
  'alane',
  'alani',
  'alano',
  'alare',
  'alari',
  'alata',
  'alate',
  'alati',
  'alato',
  'alava',
  'alavi',
  'alavo',
  'albio',
  'alcea',
  'alcee',
  'alche',
  'aldio',
  'alesa',
  'alese',
  'alesi',
  'aleso',
  'algia',
  'algie',
  'aliai',
  'alibi',
  'alice',
  'alici',
  'alida',
  'alide',
  'alidi',
  'alido',
  'alimi',
  'alimo',
  'alino',
  'alita',
  'alite',
  'allea',
  'allee',
  'allei',
  'alleo',
  'almea',
  'almee',
  'alobi',
  'alona',
  'alone',
  'aloni',
  'alono',
  'alosa',
  'alose',
  'alpha',
  'altea',
  'altee',
  'alula',
  'alule',
  'alvei',
  'alveo',
  'amaci',
  'amala',
  'amale',
  'amali',
  'amalo',
  'amami',
  'amane',
  'amasi',
  'ambia',
  'ambii',
  'ambio',
  'ambra',
  'ambre',
  'ameba',
  'amebe',
  'amida',
  'amide',
  'amidi',
  'amido',
  'amiga',
  'amigo',
  'amile',
  'amili',
  'amina',
  'amine',
  'ammai',
  'amnii',
  'amnio',
  'amomi',
  'amomo',
  'ampia',
  'amuli',
  'amulo',
  'anale',
  'anali',
  'anari',
  'ancia',
  'anela',
  'anele',
  'aneli',
  'anelo',
  'aneti',
  'aneto',
  'angla',
  'angle',
  'angli',
  'anglo',
  'angue',
  'angui',
  'anice',
  'anici',
  'anile',
  'anili',
  'annua',
  'annue',
  'annui',
  'annuo',
  'anobi',
  'anodi',
  'anodo',
  'anona',
  'anone',
  'ansai',
  'ansio',
  'anura',
  'anure',
  'anuri',
  'anuro',
  'aonia',
  'aonie',
  'aonio',
  'aorta',
  'aorte',
  'apale',
  'apali',
  'apate',
  'apide',
  'apidi',
  'apina',
  'apine',
  'apira',
  'apire',
  'apiri',
  'apiro',
  'apnea',
  'apnee',
  'apoda',
  'apode',
  'apodi',
  'apodo',
  'appia',
  'appie',
  'appio',
  'apula',
  'apule',
  'apuli',
  'apulo',
  'araba',
  'arabe',
  'arabi',
  'arabo',
  'araci',
  'arala',
  'arale',
  'arali',
  'aralo',
  'arama',
  'arame',
  'arami',
  'aramo',
  'arane',
  'arara',
  'arcai',
  'arche',
  'arcua',
  'arcui',
  'arcuo',
  'ardea',
  'ardee',
  'ardii',
  'areai',
  'areca',
  'arena',
  'arene',
  'areni',
  'areno',
  'arese',
  'aresi',
  'argea',
  'argee',
  'argei',
  'argeo',
  'arghi',
  'arile',
  'arili',
  'arnia',
  'arnie',
  'arpia',
  'arpie',
  'arsii',
  'artai',
  'artoa',
  'artoe',
  'artoi',
  'artoo',
  'asada',
  'asado',
  'asana',
  'asari',
  'asaro',
  'aschi',
  'ascio',
  'asoli',
  'asolo',
  'assai',
  'astia',
  'astio',
  'atava',
  'atave',
  'atavi',
  'atavo',
  'atele',
  'ateli',
  'atona',
  'atone',
  'atoni',
  'atono',
  'attai',
  'attea',
  'attee',
  'attei',
  'atteo',
  'audio',
  'auffa',
  'augna',
  'augni',
  'augno',
  'aulii',
  'aurai',
  'avana',
  'avena',
  'avene',
  'averi',
  'avita',
  'avite',
  'aviti',
  'avito',
  'avoca',
  'avoco',
  'avola',
  'avole',
  'avoli',
  'avolo',
  'avori',
  'avuta',
  'avute',
  'avuti',
  'axone',
  'axoni',
  'azera',
  'azere',
  'azeri',
  'azero',
  'azide',
  'azidi',
  'azima',
  'azime',
  'azimi',
  'azimo',
  'azina',
  'azine',
  'azoni',
  'azota',
  'azoti',
  'azoto',
  'azuki',
  'babao',
  'bacai',
  'bacca',
  'bacco',
  'bacie',
  'bacii',
  'badge',
  'badgi',
  'badia',
  'badie',
  'baele',
  'baeli',
  'bagli',
  'bagne',
  'baiai',
  'baili',
  'bailo',
  'baino',
  'baita',
  'baite',
  'bakka',
  'bakke',
  'bakki',
  'bakko',
  'balba',
  'balbe',
  'balbi',
  'balbo',
  'balco',
  'balda',
  'balde',
  'baldi',
  'baldo',
  'balii',
  'balio',
  'balle',
  'balma',
  'balme',
  'balsa',
  'balse',
  'balta',
  'balte',
  'balti',
  'balto',
  'bamba',
  'bambe',
  'bambi',
  'bambo',
  'banaa',
  'banae',
  'banai',
  'banao',
  'banco',
  'bando',
  'bangi',
  'banjo',
  'banna',
  'banni',
  'banno',
  'barbi',
  'barbo',
  'barco',
  'barda',
  'barde',
  'bardi',
  'bardo',
  'baria',
  'barie',
  'barii',
  'bario',
  'barri',
  'barro',
  'basca',
  'basco',
  'basii',
  'basse',
  'bassi',
  'baste',
  'batua',
  'baula',
  'baulo',
  'bauta',
  'baute',
  'bazza',
  'bazze',
  'beaci',
  'beala',
  'beale',
  'beali',
  'bealo',
  'beami',
  'beane',
  'beano',
  'beare',
  'beava',
  'beavi',
  'beavo',
  'becca',
  'beffi',
  'beffo',
  'begai',
  'beggi',
  'beghe',
  'beghi',
  'begia',
  'begli',
  'beige',
  'beigi',
  'beino',
  'beisa',
  'beise',
  'belai',
  'belga',
  'belgi',
  'belii',
  'belio',
  'benai',
  'benna',
  'benne',
  'beola',
  'beole',
  'beona',
  'beota',
  'beote',
  'beoti',
  'berla',
  'berle',
  'berli',
  'berma',
  'berme',
  'bermi',
  'berne',
  'berta',
  'berte',
  'berti',
  'bervi',
  'besce',
  'besci',
  'bessa',
  'besse',
  'bessi',
  'besso',
  'betta',
  'bette',
  'beuta',
  'beute',
  'bevei',
  'bevve',
  'bevvi',
  'bezzi',
  'bezzo',
  'biada',
  'biade',
  'biadi',
  'biado',
  'biava',
  'biave',
  'biavi',
  'biavo',
  'bibbi',
  'biche',
  'bieca',
  'bieta',
  'biete',
  'biffa',
  'biffe',
  'biffi',
  'biffo',
  'bighe',
  'bighi',
  'bigia',
  'bigie',
  'bigio',
  'bilia',
  'bilie',
  'binai',
  'binda',
  'binde',
  'bindi',
  'binga',
  'binge',
  'bingo',
  'biodi',
  'biodo',
  'bioma',
  'biomi',
  'biosi',
  'biova',
  'biove',
  'birba',
  'birbe',
  'birbi',
  'birbo',
  'birce',
  'birci',
  'birri',
  'birro',
  'bissa',
  'bissi',
  'bisso',
  'bista',
  'biste',
  'bisti',
  'bitta',
  'bitte',
  'bitti',
  'bitto',
  'biuta',
  'biute',
  'biuti',
  'biuto',
  'bivio',
  'bizza',
  'bizze',
  'blesa',
  'blese',
  'blesi',
  'bleso',
  'blini',
  'blumi',
  'blumo',
  'blusa',
  'bluse',
  'boari',
  'boaro',
  'boati',
  'boato',
  'bobba',
  'bobbi',
  'bobbo',
  'bocco',
  'bocia',
  'boema',
  'boeme',
  'boemi',
  'boemo',
  'boera',
  'boere',
  'boeri',
  'boero',
  'boghe',
  'boide',
  'boidi',
  'boite',
  'boldi',
  'boldo',
  'bolge',
  'bolsa',
  'bolse',
  'bolsi',
  'bolso',
  'bombi',
  'bombo',
  'bongo',
  'bonne',
  'bonza',
  'bonze',
  'bonzi',
  'bonzo',
  'borda',
  'borde',
  'borea',
  'borei',
  'borio',
  'borni',
  'borra',
  'borre',
  'borri',
  'borro',
  'bossi',
  'bosso',
  'botri',
  'botro',
  'botti',
  'botto',
  'boule',
  'boxai',
  'bozzi',
  'bozzo',
  'braca',
  'braco',
  'brada',
  'brade',
  'bradi',
  'brado',
  'braga',
  'brago',
  'braii',
  'brame',
  'brasa',
  'brasi',
  'braso',
  'bremi',
  'bremo',
  'breva',
  'brice',
  'brici',
  'brida',
  'bride',
  'briga',
  'brige',
  'brigi',
  'brigo',
  'brini',
  'brino',
  'broda',
  'brode',
  'broge',
  'brogi',
  'broli',
  'brolo',
  'bromi',
  'bromo',
  'brova',
  'brovi',
  'brovo',
  'bruca',
  'brugi',
  'brugo',
  'bruii',
  'bruma',
  'brume',
  'bruta',
  'brute',
  'bruzi',
  'buana',
  'bubai',
  'bucai',
  'bucci',
  'bucio',
  'budda',
  'buddi',
  'bufai',
  'bugio',
  'bugli',
  'bugna',
  'bugne',
  'bugni',
  'bugno',
  'buina',
  'buine',
  'bulai',
  'bulla',
  'bulle',
  'bulli',
  'bullo',
  'bumba',
  'bumbe',
  'burba',
  'burbe',
  'burga',
  'burka',
  'burqa',
  'busaa',
  'busae',
  'busai',
  'busao',
  'busca',
  'busco',
  'bushi',
  'busse',
  'busta',
  'buste',
  'butte',
  'buzza',
  'buzze',
  'buzzi',
  'buzzo',
  'bwaka',
  'bwana',
  'caaba',
  'cabla',
  'cabli',
  'cablo',
  'cabra',
  'cabri',
  'cabro',
  'cacai',
  'cacca',
  'cache',
  'cachi',
  'cacia',
  'cacio',
  'cacti',
  'cacto',
  'cadmi',
  'caffa',
  'caffi',
  'caffo',
  'cafra',
  'cafre',
  'cafri',
  'cafro',
  'cagai',
  'caghi',
  'cagli',
  'caiba',
  'caibe',
  'caina',
  'caine',
  'caini',
  'caino',
  'calao',
  'calca',
  'calce',
  'calia',
  'calie',
  'calla',
  'calle',
  'calse',
  'calta',
  'calte',
  'camei',
  'cameo',
  'camma',
  'camme',
  'canai',
  'canea',
  'canee',
  'canga',
  'cange',
  'cangi',
  'canni',
  'canno',
  'canoa',
  'canoe',
  'cansa',
  'cansi',
  'canso',
  'capai',
  'capii',
  'cappa',
  'cappe',
  'cappi',
  'cappo',
  'capri',
  'capro',
  'capta',
  'capti',
  'capto',
  'carca',
  'carco',
  'carda',
  'carde',
  'cardi',
  'cardo',
  'cargo',
  'caria',
  'carie',
  'cario',
  'carme',
  'carmi',
  'carna',
  'carno',
  'carpa',
  'carpe',
  'carpi',
  'carpo',
  'carsi',
  'carso',
  'carvi',
  'casba',
  'casbe',
  'casca',
  'casci',
  'casei',
  'caseo',
  'caspa',
  'caspe',
  'caspi',
  'cassi',
  'casso',
  'catta',
  'catte',
  'catti',
  'catto',
  'caule',
  'cauli',
  'cauri',
  'cavea',
  'cavee',
  'cavia',
  'cavie',
  'cazza',
  'cazze',
  'cazzi',
  'cazzo',
  'cccii',
  'cccli',
  'cccvi',
  'cccxi',
  'cciii',
  'cclii',
  'cclvi',
  'cclxi',
  'ccvii',
  'ccxci',
  'ccxii',
  'ccxli',
  'ccxvi',
  'ccxxi',
  'cdiii',
  'cdlii',
  'cdlvi',
  'cdlxi',
  'cdvii',
  'cdxci',
  'cdxii',
  'cdxli',
  'cdxvi',
  'cdxxi',
  'cecai',
  'cecca',
  'ceche',
  'cechi',
  'cecio',
  'cedei',
  'cedra',
  'cedua',
  'cedue',
  'cedui',
  'ceduo',
  'ceffa',
  'ceffi',
  'ceffo',
  'ceiba',
  'ceibe',
  'celia',
  'celie',
  'celio',
  'celli',
  'cello',
  'celta',
  'celte',
  'celti',
  'celto',
  'celzi',
  'cenci',
  'cenge',
  'cengi',
  'cenna',
  'censi',
  'censo',
  'ceppa',
  'ceppe',
  'ceppi',
  'ceppo',
  'cerai',
  'cerci',
  'cerea',
  'ceree',
  'cerei',
  'cereo',
  'cerii',
  'cerio',
  'cerna',
  'cerne',
  'cerni',
  'cerno',
  'cerri',
  'cerro',
  'cerva',
  'cerve',
  'cesia',
  'cesie',
  'cesio',
  'cespi',
  'cespo',
  'cesse',
  'ceste',
  'cesto',
  'chela',
  'chele',
  'cheli',
  'cheta',
  'chete',
  'cheti',
  'cheto',
  'chile',
  'chili',
  'chilo',
  'chimi',
  'chimo',
  'chita',
  'chiti',
  'chito',
  'cholo',
  'chope',
  'ciaba',
  'ciabi',
  'ciabo',
  'ciana',
  'ciane',
  'ciani',
  'ciano',
  'ciati',
  'ciato',
  'ciazi',
  'cibai',
  'cicce',
  'cicci',
  'cicco',
  'ciche',
  'cidia',
  'cidie',
  'cidio',
  'ciesa',
  'ciese',
  'cifri',
  'cifro',
  'cigna',
  'cigne',
  'cimai',
  'cimba',
  'cimbe',
  'cince',
  'cinga',
  'cinge',
  'cingi',
  'cingo',
  'cinsi',
  'cinte',
  'cinti',
  'cinzi',
  'cioce',
  'ciofi',
  'ciofo',
  'cippi',
  'cippo',
  'cipri',
  'circe',
  'circi',
  'cirri',
  'cirro',
  'cisio',
  'cispa',
  'cispe',
  'cista',
  'cisto',
  'citte',
  'citti',
  'citto',
  'ciuca',
  'ciuco',
  'ciula',
  'ciuli',
  'ciulo',
  'clade',
  'cladi',
  'clama',
  'clami',
  'clamo',
  'clava',
  'clave',
  'clavi',
  'clavo',
  'cleri',
  'cliii',
  'cline',
  'clini',
  'clino',
  'clivi',
  'clivo',
  'clona',
  'clone',
  'cloni',
  'clono',
  'clora',
  'clori',
  'cloro',
  'cloze',
  'clune',
  'cluni',
  'clvii',
  'clxii',
  'clxvi',
  'clxxi',
  'cmiii',
  'cmlii',
  'cmlvi',
  'cmlxi',
  'cmvii',
  'cmxci',
  'cmxii',
  'cmxli',
  'cmxvi',
  'cmxxi',
  'cnidi',
  'coala',
  'coana',
  'coane',
  'coati',
  'cobea',
  'cobee',
  'cobla',
  'coble',
  'cobra',
  'cocca',
  'cocce',
  'cocci',
  'cocco',
  'coche',
  'codia',
  'codio',
  'coesa',
  'coese',
  'coesi',
  'coeso',
  'coeva',
  'coeve',
  'coevi',
  'coevo',
  'coffa',
  'coffe',
  'cofta',
  'cofte',
  'cofti',
  'cofto',
  'cogli',
  'cogni',
  'cogno',
  'coiai',
  'coipi',
  'coipo',
  'coire',
  'coita',
  'coite',
  'coiti',
  'coito',
  'coiva',
  'coivi',
  'coivo',
  'colca',
  'colco',
  'colei',
  'colga',
  'colgo',
  'colia',
  'colie',
  'colsi',
  'colte',
  'colui',
  'colza',
  'colze',
  'comba',
  'combe',
  'combo',
  'comma',
  'commi',
  'commo',
  'compi',
  'conca',
  'conce',
  'conga',
  'congi',
  'conie',
  'conne',
  'conni',
  'conno',
  'consa',
  'conso',
  'cooba',
  'coobi',
  'coobo',
  'coppa',
  'coppe',
  'coppi',
  'coppo',
  'copta',
  'copte',
  'copti',
  'copto',
  'coque',
  'corai',
  'corba',
  'corbe',
  'corca',
  'corco',
  'corea',
  'coree',
  'corei',
  'coreo',
  'corio',
  'cormi',
  'cormo',
  'corni',
  'cosai',
  'cosca',
  'cosci',
  'cosfi',
  'cosse',
  'cossi',
  'cosso',
  'cousi',
  'couso',
  'crace',
  'craci',
  'crapa',
  'crape',
  'crasi',
  'crena',
  'crene',
  'creni',
  'creno',
  'cresi',
  'creso',
  'crime',
  'crimi',
  'crina',
  'crine',
  'crini',
  'crino',
  'croco',
  'croda',
  'crode',
  'croia',
  'croie',
  'croio',
  'croma',
  'crome',
  'cromi',
  'crono',
  'cruci',
  'ctoni',
  'cubai',
  'cubia',
  'cubie',
  'cucca',
  'cucce',
  'cucci',
  'cucco',
  'cucia',
  'cucii',
  'cucio',
  'cueva',
  'cugna',
  'cugne',
  'culai',
  'culmi',
  'culmo',
  'culpa',
  'culta',
  'culte',
  'cunca',
  'cunce',
  'cunci',
  'cunco',
  'cunei',
  'cuneo',
  'cuoce',
  'cuoci',
  'cuoia',
  'cuora',
  'curda',
  'curde',
  'curdi',
  'curdo',
  'curia',
  'curie',
  'curii',
  'curio',
  'curri',
  'curro',
  'cusco',
  'cussi',
  'cusso',
  'cviii',
  'cwana',
  'cxcii',
  'cxcvi',
  'cxiii',
  'cxlii',
  'cxlvi',
  'cxvii',
  'cxxii',
  'cxxvi',
  'cxxxi',
  'dache',
  'dacia',
  'dacie',
  'dafne',
  'dafni',
  'daghe',
  'daina',
  'daine',
  'dalai',
  'dalia',
  'dalie',
  'dalli',
  'damai',
  'damma',
  'damme',
  'dance',
  'danda',
  'dande',
  'danne',
  'dante',
  'danti',
  'dardi',
  'dardo',
  'darli',
  'darlo',
  'daspo',
  'dauni',
  'dazia',
  'dccci',
  'dccii',
  'dccli',
  'dccvi',
  'dccxi',
  'dciii',
  'dclii',
  'dclvi',
  'dclxi',
  'dcvii',
  'dcxci',
  'dcxii',
  'dcxli',
  'dcxvi',
  'dcxxi',
  'debbi',
  'deche',
  'deghi',
  'delia',
  'delie',
  'delio',
  'delta',
  'denta',
  'dento',
  'derma',
  'dermi',
  'derno',
  'desco',
  'desia',
  'desii',
  'desio',
  'dessa',
  'desso',
  'deste',
  'devia',
  'devie',
  'devii',
  'devio',
  'diade',
  'diadi',
  'diale',
  'diali',
  'diana',
  'diane',
  'diapa',
  'diape',
  'diapi',
  'diapo',
  'diate',
  'diaza',
  'diaze',
  'diazi',
  'diazo',
  'dicai',
  'dicco',
  'diche',
  'dichi',
  'dicta',
  'diene',
  'dieni',
  'dieri',
  'diesa',
  'diesi',
  'dieso',
  'dieti',
  'dieto',
  'dildi',
  'dildo',
  'dilli',
  'dimoi',
  'dindi',
  'dindo',
  'dingo',
  'dinka',
  'diodi',
  'diodo',
  'dioli',
  'diolo',
  'diosi',
  'dipoi',
  'dirli',
  'disfa',
  'disfi',
  'disfo',
  'disia',
  'disii',
  'disio',
  'ditta',
  'ditte',
  'ditti',
  'diula',
  'djaga',
  'dliii',
  'dlvii',
  'dlxii',
  'dlxvi',
  'dlxxi',
  'doagi',
  'doari',
  'dobla',
  'doble',
  'dobli',
  'doblo',
  'dobra',
  'docci',
  'docmi',
  'dogai',
  'doghe',
  'doghi',
  'dogli',
  'dogre',
  'dolai',
  'dolca',
  'dolco',
  'dolga',
  'dolgo',
  'dolio',
  'dolse',
  'dolsi',
  'dolta',
  'dolte',
  'dolti',
  'dolto',
  'domma',
  'dommi',
  'donde',
  'donni',
  'donno',
  'dopai',
  'dorai',
  'doria',
  'dorio',
  'dossi',
  'dosso',
  'doula',
  'doule',
  'dovei',
  'draba',
  'drabe',
  'draco',
  'drena',
  'dreni',
  'dreno',
  'drive',
  'droga',
  'drogo',
  'droma',
  'drome',
  'dromi',
  'dromo',
  'drone',
  'droni',
  'druda',
  'drude',
  'drudi',
  'drudo',
  'drupa',
  'drupe',
  'drusa',
  'druse',
  'drusi',
  'druso',
  'duagi',
  'duale',
  'duali',
  'ducai',
  'ducea',
  'ducee',
  'duina',
  'duine',
  'duini',
  'duino',
  'dulia',
  'dulie',
  'duole',
  'duoli',
  'duolo',
  'dupla',
  'duple',
  'dupli',
  'duplo',
  'durio',
  'durra',
  'durre',
  'dviii',
  'dxcii',
  'dxcvi',
  'dxiii',
  'dxlii',
  'dxlvi',
  'dxvii',
  'dxxii',
  'dxxvi',
  'dxxxi',
  'ebani',
  'ebbio',
  'ebbra',
  'ebbre',
  'ebbri',
  'ebbro',
  'ebeni',
  'ebeno',
  'ebeta',
  'ebeto',
  'ebola',
  'ebole',
  'ebuli',
  'ebulo',
  'echei',
  'echeo',
  'echio',
  'ecidi',
  'edace',
  'edaci',
  'edema',
  'edemi',
  'edile',
  'edili',
  'edipi',
  'edipo',
  'edita',
  'edite',
  'editi',
  'edito',
  'edona',
  'edone',
  'edoni',
  'edono',
  'educe',
  'educi',
  'edule',
  'eduli',
  'efebi',
  'efebo',
  'efesi',
  'efori',
  'eforo',
  'egena',
  'egene',
  'egeni',
  'egeno',
  'egida',
  'egide',
  'egira',
  'egire',
  'egizi',
  'ekiti',
  'elafe',
  'elafi',
  'eleni',
  'elice',
  'elici',
  'elida',
  'elide',
  'elidi',
  'elido',
  'eliea',
  'eliee',
  'elima',
  'elime',
  'elimi',
  'elimo',
  'elisa',
  'elise',
  'elisi',
  'eliso',
  'eluda',
  'eluii',
  'eluvi',
  'emaci',
  'emale',
  'emali',
  'emeri',
  'emero',
  'emesi',
  'emica',
  'emici',
  'emico',
  'emide',
  'emidi',
  'emina',
  'emine',
  'emiri',
  'emiro',
  'emise',
  'emisi',
  'emoji',
  'emoni',
  'empia',
  'empie',
  'empii',
  'empio',
  'emule',
  'enema',
  'enemi',
  'enfia',
  'enfie',
  'enfio',
  'engri',
  'ennea',
  'ennee',
  'ennei',
  'enneo',
  'enoli',
  'enolo',
  'enula',
  'enule',
  'eolia',
  'eolie',
  'eolio',
  'epodi',
  'epodo',
  'equai',
  'erada',
  'erade',
  'eradi',
  'erado',
  'erari',
  'erasa',
  'erase',
  'erasi',
  'eraso',
  'erbai',
  'erbio',
  'erebi',
  'erebo',
  'ereda',
  'eredo',
  'eremi',
  'eremo',
  'ereta',
  'ereti',
  'ereto',
  'erica',
  'eriga',
  'ernia',
  'ernie',
  'ernio',
  'eroda',
  'erode',
  'erodi',
  'erodo',
  'eroga',
  'erogo',
  'erosa',
  'erose',
  'erosi',
  'eroso',
  'eruca',
  'erula',
  'erule',
  'eruli',
  'erulo',
  'erzya',
  'esala',
  'esali',
  'esalo',
  'esani',
  'esano',
  'eschi',
  'esiga',
  'esigi',
  'esigo',
  'esima',
  'esipi',
  'esipo',
  'esita',
  'esizi',
  'esodi',
  'esodo',
  'esone',
  'esoni',
  'esora',
  'esori',
  'esoro',
  'esosa',
  'esose',
  'esosi',
  'esoso',
  'essai',
  'estia',
  'estie',
  'estii',
  'estio',
  'estri',
  'estro',
  'estua',
  'estui',
  'estuo',
  'esulo',
  'esuma',
  'esumi',
  'esumo',
  'etani',
  'etano',
  'etate',
  'etati',
  'etene',
  'eteni',
  'etera',
  'eteri',
  'etero',
  'etesi',
  'etica',
  'etici',
  'etico',
  'etile',
  'etili',
  'etimi',
  'etimo',
  'etini',
  'etino',
  'etnea',
  'etnee',
  'etnei',
  'etneo',
  'etnia',
  'etnie',
  'etola',
  'etole',
  'etoli',
  'etolo',
  'eutri',
  'evada',
  'evade',
  'evadi',
  'evado',
  'evasa',
  'evase',
  'evasi',
  'evaso',
  'evira',
  'eviri',
  'eviro',
  'evoca',
  'evoco',
  'extra',
  'fabri',
  'fabro',
  'facto',
  'faida',
  'faide',
  'faina',
  'faine',
  'falba',
  'falbe',
  'falbi',
  'falbo',
  'falca',
  'faldi',
  'faldo',
  'famai',
  'fanga',
  'farce',
  'farda',
  'farde',
  'farea',
  'farri',
  'farro',
  'farsa',
  'farse',
  'farso',
  'fasai',
  'fasta',
  'faste',
  'fasti',
  'fasto',
  'fatai',
  'fatua',
  'fatue',
  'fatui',
  'fatuo',
  'fatwa',
  'fauce',
  'fauci',
  'fauna',
  'faune',
  'fauni',
  'fauno',
  'fauve',
  'favai',
  'faxai',
  'feace',
  'feaci',
  'febea',
  'febee',
  'febei',
  'febeo',
  'fecce',
  'fedai',
  'felce',
  'felci',
  'fella',
  'felle',
  'felli',
  'fello',
  'felpa',
  'felpe',
  'felpi',
  'felpo',
  'felse',
  'felsi',
  'felze',
  'felzi',
  'femme',
  'fence',
  'fenda',
  'feria',
  'ferla',
  'ferle',
  'ferra',
  'fersa',
  'ferse',
  'ferva',
  'ferve',
  'fervi',
  'fervo',
  'ferza',
  'ferze',
  'ferzi',
  'ferzo',
  'festi',
  'festo',
  'feudi',
  'feudo',
  'fiaba',
  'fiabe',
  'fiala',
  'fiale',
  'fiani',
  'fiano',
  'fiata',
  'fiate',
  'fiati',
  'fiato',
  'fibra',
  'fibre',
  'ficca',
  'ficco',
  'ficea',
  'ficee',
  'fiche',
  'fidai',
  'fiele',
  'fieli',
  'fighe',
  'fighi',
  'filli',
  'fillo',
  'filma',
  'filmi',
  'filmo',
  'filza',
  'filze',
  'finca',
  'finga',
  'finna',
  'finne',
  'finni',
  'finno',
  'finta',
  'finte',
  'finti',
  'fioca',
  'fioco',
  'fiora',
  'fioro',
  'fiote',
  'fioti',
  'fisai',
  'flake',
  'flana',
  'flani',
  'flano',
  'flati',
  'flato',
  'flava',
  'flave',
  'flavi',
  'flavo',
  'flebo',
  'fleti',
  'fleto',
  'flora',
  'flore',
  'fobia',
  'fobie',
  'focea',
  'focee',
  'focei',
  'foceo',
  'fochi',
  'fodri',
  'fodro',
  'fogge',
  'foggi',
  'foghe',
  'fogni',
  'fogno',
  'foiba',
  'foibe',
  'folce',
  'folco',
  'folio',
  'folli',
  'follo',
  'fonai',
  'fonde',
  'fonia',
  'fonie',
  'fonte',
  'fonti',
  'forca',
  'force',
  'forci',
  'forge',
  'forgi',
  'forra',
  'forre',
  'fosca',
  'fosso',
  'fotta',
  'fotte',
  'fotti',
  'fotto',
  'fovea',
  'fovee',
  'fraga',
  'frale',
  'frali',
  'frame',
  'frega',
  'fregi',
  'frego',
  'frema',
  'freme',
  'fremi',
  'fremo',
  'frena',
  'fresa',
  'frese',
  'fresi',
  'freso',
  'frigi',
  'frigo',
  'frine',
  'frini',
  'frisa',
  'frisi',
  'friso',
  'froci',
  'frode',
  'froge',
  'fruii',
  'ftori',
  'ftoro',
  'fuchi',
  'fuffa',
  'fuffe',
  'fuggo',
  'fulbe',
  'fulbi',
  'fulga',
  'fulge',
  'fulgi',
  'fulgo',
  'fulsa',
  'fulse',
  'fulsi',
  'fulso',
  'fulva',
  'fulve',
  'fulvi',
  'fulvo',
  'fumea',
  'fumee',
  'fumei',
  'fumeo',
  'fumma',
  'fummi',
  'funai',
  'fundo',
  'funga',
  'fungi',
  'funse',
  'funsi',
  'funta',
  'funte',
  'funti',
  'funto',
  'furai',
  'furca',
  'furia',
  'furie',
  'furio',
  'fusai',
  'fusta',
  'fuste',
  'fuxia',
  'fuxie',
  'gabba',
  'gabbi',
  'gabbo',
  'gaela',
  'gaele',
  'gaeli',
  'gaelo',
  'gaffa',
  'gaffe',
  'gagge',
  'gaggi',
  'galea',
  'galee',
  'galei',
  'galeo',
  'galgo',
  'galio',
  'galla',
  'galle',
  'gamia',
  'gamie',
  'gamme',
  'ganda',
  'gande',
  'ganga',
  'gange',
  'ganza',
  'ganze',
  'ganzi',
  'ganzo',
  'garba',
  'garbe',
  'garbi',
  'garga',
  'gargo',
  'garni',
  'garzi',
  'garzo',
  'gasai',
  'gasco',
  'gassa',
  'gasse',
  'gassi',
  'gasso',
  'gatta',
  'gatte',
  'gatti',
  'gatto',
  'gauda',
  'gaude',
  'gaudi',
  'gaudo',
  'gauge',
  'gauli',
  'gaulo',
  'gauta',
  'gaute',
  'gauti',
  'gauto',
  'gazai',
  'gazza',
  'gazze',
  'gazzi',
  'gazzo',
  'gechi',
  'gelao',
  'gelsa',
  'gelse',
  'gemei',
  'gemmi',
  'gemmo',
  'genai',
  'genia',
  'genie',
  'genoa',
  'genti',
  'genue',
  'geode',
  'geodi',
  'geomi',
  'gerba',
  'gerbe',
  'gerla',
  'gerle',
  'gerli',
  'gerlo',
  'germa',
  'gessa',
  'gesta',
  'ghali',
  'ghega',
  'ghegi',
  'ghego',
  'ghiri',
  'ghiro',
  'ghise',
  'giaci',
  'giaco',
  'giada',
  'giade',
  'giani',
  'giano',
  'giava',
  'giave',
  'gibbi',
  'gibbo',
  'gighe',
  'gilda',
  'gilde',
  'gimmo',
  'ginna',
  'ginni',
  'ginno',
  'gioio',
  'girci',
  'girei',
  'girii',
  'girio',
  'girla',
  'girle',
  'girli',
  'girlo',
  'girmi',
  'girne',
  'girsi',
  'girti',
  'girvi',
  'gisse',
  'gissi',
  'giste',
  'gisti',
  'gitta',
  'gitti',
  'gitto',
  'giuba',
  'giube',
  'giuda',
  'giuli',
  'giure',
  'giuso',
  'gladi',
  'gleba',
  'glebe',
  'glena',
  'glene',
  'gleni',
  'glifi',
  'glifo',
  'globe',
  'glomi',
  'glomo',
  'glori',
  'gluma',
  'glume',
  'gnidi',
  'gnome',
  'gnomi',
  'gnomo',
  'gnosi',
  'gnuda',
  'gnude',
  'gnudi',
  'gnudo',
  'gocci',
  'godei',
  'godii',
  'godio',
  'gogna',
  'gogne',
  'goldi',
  'golea',
  'golei',
  'goleo',
  'golia',
  'golpe',
  'gombe',
  'gombi',
  'gombo',
  'gommi',
  'gommo',
  'gonda',
  'gonde',
  'gondi',
  'gondo',
  'gonfa',
  'gonfe',
  'gonfo',
  'gonga',
  'gonio',
  'gonja',
  'gonza',
  'gonze',
  'gonzi',
  'gonzo',
  'gordi',
  'gorga',
  'gorge',
  'gorna',
  'gorne',
  'gotha',
  'gotta',
  'gotte',
  'gotti',
  'gotto',
  'gouda',
  'gozzi',
  'gozzo',
  'grada',
  'grade',
  'graia',
  'graie',
  'graii',
  'graio',
  'grama',
  'grame',
  'grami',
  'gramo',
  'greca',
  'greci',
  'greti',
  'greto',
  'greve',
  'grevi',
  'grica',
  'grici',
  'grico',
  'gride',
  'grifa',
  'grifi',
  'grifo',
  'grola',
  'grole',
  'groma',
  'grome',
  'grufa',
  'grufi',
  'grufo',
  'gruga',
  'grugo',
  'gruma',
  'grume',
  'grumi',
  'grumo',
  'grusi',
  'guaco',
  'guade',
  'guaii',
  'guani',
  'guano',
  'guata',
  'guati',
  'guato',
  'gufai',
  'guina',
  'guini',
  'guino',
  'guisa',
  'guise',
  'gurge',
  'gurgi',
  'gusla',
  'gutea',
  'gutee',
  'gutei',
  'guteo',
  'gutti',
  'gutto',
  'guzla',
  'guzle',
  'gwari',
  'hafni',
  'haida',
  'hakka',
  'hallo',
  'hamza',
  'haute',
  'hedge',
  'hello',
  'henna',
  'henne',
  'hevea',
  'hevee',
  'hindi',
  'hokka',
  'hokke',
  'hokki',
  'hokko',
  'holmi',
  'house',
  'huave',
  'huavi',
  'huchi',
  'hudna',
  'iacea',
  'iacee',
  'iarda',
  'iarde',
  'ibera',
  'ibere',
  'iberi',
  'ibero',
  'iblea',
  'iblee',
  'iblei',
  'ibleo',
  'icari',
  'icena',
  'icene',
  'iceni',
  'iceno',
  'icona',
  'icone',
  'icore',
  'icori',
  'idali',
  'iddia',
  'iddie',
  'iddio',
  'ideai',
  'idria',
  'idrie',
  'iella',
  'ielle',
  'iesta',
  'ieste',
  'iesti',
  'iesto',
  'ietta',
  'ietti',
  'ietto',
  'ifale',
  'ifali',
  'ifema',
  'ifemi',
  'igloo',
  'ignea',
  'ignee',
  'ignei',
  'igneo',
  'ilare',
  'ilari',
  'ilice',
  'ilici',
  'ilide',
  'ilidi',
  'illea',
  'illee',
  'illei',
  'illeo',
  'ilota',
  'ilote',
  'iloti',
  'image',
  'imago',
  'imani',
  'imano',
  'imati',
  'imene',
  'imeni',
  'inala',
  'inali',
  'inalo',
  'inane',
  'inani',
  'incoa',
  'incoi',
  'incoo',
  'india',
  'indie',
  'indio',
  'infoi',
  'infra',
  'ingoi',
  'ingri',
  'inlei',
  'inlui',
  'inmia',
  'inmio',
  'inoli',
  'inoma',
  'inomi',
  'inope',
  'inopi',
  'insce',
  'insci',
  'insta',
  'insti',
  'insto',
  'intra',
  'intro',
  'inula',
  'inule',
  'inuma',
  'inumi',
  'inumo',
  'invai',
  'iodai',
  'ioide',
  'ioidi',
  'iolla',
  'iolle',
  'ionia',
  'ionie',
  'ionio',
  'ipate',
  'ipati',
  'ippia',
  'ippie',
  'ippii',
  'ippio',
  'irace',
  'iraci',
  'irata',
  'irate',
  'irati',
  'irato',
  'irchi',
  'irida',
  'iridi',
  'irido',
  'irite',
  'iriti',
  'iroki',
  'iroko',
  'irosa',
  'irose',
  'irosi',
  'iroso',
  'irula',
  'ischi',
  'isodi',
  'isopi',
  'isopo',
  'issai',
  'istai',
  'istle',
  'isuri',
  'isuro',
  'itaci',
  'itaco',
  'itala',
  'itale',
  'itali',
  'italo',
  'itera',
  'iteri',
  'itero',
  'iucca',
  'iulca',
  'iulco',
  'iurta',
  'iurte',
  'ivata',
  'ivate',
  'ivati',
  'ivato',
  'joule',
  'jumbo',
  'junci',
  'junco',
  'kaaba',
  'kakwa',
  'kamti',
  'kanji',
  'kansa',
  'kaone',
  'kaoni',
  'kappa',
  'karma',
  'kasba',
  'kendo',
  'khasi',
  'kiowa',
  'kissi',
  'kitai',
  'kiwai',
  'koala',
  'konde',
  'kondi',
  'konga',
  'konge',
  'kongi',
  'kongo',
  'konta',
  'kulla',
  'kulle',
  'kulli',
  'kullo',
  'kunta',
  'kurda',
  'kurde',
  'kurdi',
  'kurdo',
  'kwena',
  'kyrie',
  'labbi',
  'labbo',
  'labda',
  'labio',
  'labri',
  'labro',
  'lacce',
  'lacco',
  'lagna',
  'lagne',
  'lagni',
  'lagno',
  'laida',
  'laide',
  'laidi',
  'laido',
  'lamai',
  'lamba',
  'lambe',
  'lambo',
  'lamia',
  'lamie',
  'lamio',
  'lampa',
  'lampe',
  'lanca',
  'lanea',
  'lanee',
  'lanei',
  'laneo',
  'lanfa',
  'lanfe',
  'langa',
  'lange',
  'lania',
  'lanio',
  'lanzi',
  'lanzo',
  'lappa',
  'lappe',
  'lappi',
  'lappo',
  'larda',
  'lardi',
  'large',
  'larva',
  'larve',
  'larvi',
  'larvo',
  'lasca',
  'lasco',
  'lassa',
  'lasse',
  'lassi',
  'lasso',
  'lasta',
  'laste',
  'latti',
  'latto',
  'lauda',
  'laude',
  'laudi',
  'laura',
  'laure',
  'lauri',
  'lauro',
  'lauta',
  'laute',
  'lauti',
  'lauto',
  'lavia',
  'lavie',
  'lavra',
  'lavre',
  'lazza',
  'lazze',
  'lazzi',
  'lazzo',
  'lebbi',
  'lecce',
  'lella',
  'lelle',
  'lelli',
  'lello',
  'lemma',
  'lemme',
  'lemmi',
  'lemmo',
  'lemna',
  'lemne',
  'lenca',
  'lenci',
  'lenea',
  'lenee',
  'lenei',
  'leneo',
  'lenii',
  'lenzi',
  'lenzo',
  'leppa',
  'leppi',
  'leppo',
  'lepra',
  'lesbi',
  'lesbo',
  'lesca',
  'letea',
  'letee',
  'letei',
  'leteo',
  'letri',
  'letro',
  'leuca',
  'leuci',
  'leuco',
  'leuti',
  'leuto',
  'lezia',
  'lezie',
  'lezio',
  'lezza',
  'lezze',
  'lezzi',
  'lezzo',
  'liane',
  'libai',
  'licci',
  'licia',
  'licie',
  'licio',
  'lidia',
  'lidie',
  'lidio',
  'lifta',
  'lifti',
  'lifto',
  'ligei',
  'ligeo',
  'ligia',
  'ligie',
  'ligio',
  'ligre',
  'ligri',
  'limai',
  'limbi',
  'limbo',
  'limii',
  'limio',
  'limma',
  'limmi',
  'linai',
  'lince',
  'linci',
  'linei',
  'lineo',
  'linfa',
  'linfe',
  'lingi',
  'linka',
  'linki',
  'linko',
  'lippa',
  'lippe',
  'lippi',
  'lippo',
  'liqua',
  'liqui',
  'liquo',
  'lisai',
  'lisce',
  'lissa',
  'lisse',
  'lissi',
  'lisso',
  'listi',
  'listo',
  'litai',
  'litio',
  'litra',
  'litre',
  'litta',
  'litte',
  'litui',
  'lituo',
  'liuti',
  'liuto',
  'lizza',
  'lizze',
  'lizzi',
  'lizzo',
  'locai',
  'locca',
  'locco',
  'lochi',
  'lodge',
  'loffa',
  'loffe',
  'loffi',
  'loffo',
  'lofio',
  'logga',
  'logge',
  'loggo',
  'loghi',
  'logia',
  'logli',
  'logra',
  'logri',
  'logro',
  'loica',
  'loici',
  'loico',
  'lolla',
  'lolle',
  'lombi',
  'lombo',
  'lompi',
  'lompo',
  'longa',
  'longo',
  'lonza',
  'lonze',
  'loppa',
  'loppe',
  'loppi',
  'loppo',
  'lorio',
  'lucci',
  'lucco',
  'lucea',
  'lucei',
  'luchi',
  'lucia',
  'lucie',
  'lucra',
  'lucri',
  'lucro',
  'ludra',
  'ludre',
  'ludri',
  'ludro',
  'luena',
  'luese',
  'luesi',
  'luffa',
  'luffe',
  'lugli',
  'lugre',
  'lugri',
  'lugro',
  'luigi',
  'lulla',
  'lulle',
  'lumai',
  'lumia',
  'lumie',
  'lumpi',
  'lumpo',
  'lunda',
  'lungi',
  'lurca',
  'lurco',
  'lusca',
  'lusco',
  'lussa',
  'lutai',
  'lutea',
  'lutee',
  'lutei',
  'luteo',
  'lutta',
  'luvia',
  'luvie',
  'luvii',
  'luvio',
  'lviii',
  'lxiii',
  'lxvii',
  'lxxii',
  'lxxvi',
  'lxxxi',
  'lycra',
  'macao',
  'macca',
  'macco',
  'machi',
  'macho',
  'macia',
  'macie',
  'macro',
  'madia',
  'madie',
  'mafia',
  'mafie',
  'mafio',
  'magai',
  'maghe',
  'maghi',
  'magia',
  'magie',
  'magio',
  'magli',
  'magma',
  'magmi',
  'magna',
  'magne',
  'magni',
  'magno',
  'maide',
  'maidi',
  'maina',
  'maini',
  'maino',
  'malga',
  'malgo',
  'malia',
  'malie',
  'malli',
  'mallo',
  'malta',
  'malte',
  'malti',
  'malto',
  'malva',
  'malve',
  'mamba',
  'mambi',
  'mambo',
  'mammi',
  'mammo',
  'manaa',
  'manae',
  'manai',
  'manao',
  'mande',
  'manga',
  'mango',
  'manna',
  'manne',
  'mansa',
  'manse',
  'mansi',
  'manso',
  'manta',
  'mante',
  'manti',
  'manto',
  'manza',
  'manze',
  'maona',
  'maone',
  'maori',
  'mappi',
  'mappo',
  'margi',
  'margo',
  'marma',
  'marna',
  'marne',
  'marni',
  'marno',
  'marra',
  'marre',
  'marsa',
  'marse',
  'marsi',
  'marso',
  'marza',
  'marze',
  'marzi',
  'masai',
  'masti',
  'maura',
  'maure',
  'mauri',
  'mauro',
  'mauve',
  'mauvi',
  'mbaka',
  'mbaya',
  'mccci',
  'mccii',
  'mccli',
  'mccvi',
  'mccxi',
  'mcdii',
  'mcdli',
  'mcdvi',
  'mcdxi',
  'mciii',
  'mclii',
  'mclvi',
  'mclxi',
  'mcmii',
  'mcmli',
  'mcmvi',
  'mcmxi',
  'mcvii',
  'mcxci',
  'mcxii',
  'mcxli',
  'mcxvi',
  'mcxxi',
  'mdcci',
  'mdcii',
  'mdcli',
  'mdcvi',
  'mdcxi',
  'mdiii',
  'mdlii',
  'mdlvi',
  'mdlxi',
  'mdvii',
  'mdxci',
  'mdxii',
  'mdxli',
  'mdxvi',
  'mdxxi',
  'meaci',
  'meala',
  'meale',
  'meali',
  'mealo',
  'meami',
  'meane',
  'meano',
  'meare',
  'meata',
  'meate',
  'meati',
  'meato',
  'meava',
  'meavi',
  'meavo',
  'mecca',
  'meino',
  'mekea',
  'mekee',
  'mekei',
  'mekeo',
  'melai',
  'melba',
  'melda',
  'melde',
  'meldi',
  'meldo',
  'melia',
  'melie',
  'melii',
  'melio',
  'meloe',
  'meloi',
  'mence',
  'menci',
  'menda',
  'mende',
  'mendi',
  'mendo',
  'menga',
  'menri',
  'meoni',
  'meoti',
  'merca',
  'merco',
  'merda',
  'merde',
  'merga',
  'merge',
  'mergi',
  'mergo',
  'meria',
  'merie',
  'merio',
  'merla',
  'merle',
  'mersa',
  'merse',
  'mersi',
  'merso',
  'mesca',
  'mesce',
  'mesci',
  'mesco',
  'meste',
  'meteo',
  'miasi',
  'micca',
  'micci',
  'micco',
  'miche',
  'micia',
  'micie',
  'micio',
  'micro',
  'mieli',
  'migma',
  'migmi',
  'migra',
  'migri',
  'migro',
  'milia',
  'milio',
  'mimai',
  'mimma',
  'mimme',
  'mimmi',
  'mimmo',
  'minai',
  'minea',
  'minee',
  'minei',
  'mineo',
  'minga',
  'minge',
  'mingi',
  'mingo',
  'minia',
  'minio',
  'minna',
  'minne',
  'minse',
  'minsi',
  'mioma',
  'miomi',
  'miosi',
  'mirra',
  'mirre',
  'mirri',
  'mirro',
  'mirti',
  'mirto',
  'misia',
  'misie',
  'misii',
  'misio',
  'missa',
  'missi',
  'misso',
  'mitra',
  'mitre',
  'mitri',
  'mitro',
  'mitti',
  'mitto',
  'mixai',
  'mliii',
  'mlvii',
  'mlxii',
  'mlxvi',
  'mlxxi',
  'mmcii',
  'mmcvi',
  'mmiii',
  'mmlii',
  'mmlvi',
  'mmlxi',
  'mmvii',
  'mmxci',
  'mmxii',
  'mmxli',
  'mmxvi',
  'mmxxi',
  'mocci',
  'mochi',
  'mocoa',
  'modio',
  'moggi',
  'mogia',
  'mogie',
  'mogio',
  'moina',
  'moine',
  'moira',
  'moire',
  'molai',
  'molca',
  'molce',
  'molli',
  'mollo',
  'molva',
  'molve',
  'monda',
  'monde',
  'monna',
  'monne',
  'moqui',
  'morai',
  'morbi',
  'morbo',
  'morde',
  'mordi',
  'mordo',
  'morfi',
  'morfo',
  'moria',
  'morie',
  'morra',
  'morre',
  'morva',
  'morve',
  'mosce',
  'mosco',
  'mosti',
  'mosto',
  'motta',
  'motte',
  'motti',
  'motto',
  'mouse',
  'movie',
  'mucci',
  'mucco',
  'muchi',
  'mudai',
  'muffi',
  'muffo',
  'mufti',
  'mughi',
  'mugli',
  'mulsi',
  'mulso',
  'multi',
  'multo',
  'mundi',
  'munga',
  'munge',
  'mungi',
  'mungo',
  'munii',
  'munse',
  'munsi',
  'munta',
  'munte',
  'munti',
  'munto',
  'muone',
  'muoni',
  'murai',
  'murge',
  'muria',
  'murie',
  'murmi',
  'murra',
  'murre',
  'musai',
  'musco',
  'mussa',
  'musse',
  'mussi',
  'musso',
  'mutua',
  'mutue',
  'mutui',
  'mutuo',
  'mviii',
  'mwera',
  'mxcii',
  'mxcvi',
  'mxiii',
  'mxlii',
  'mxlvi',
  'mxvii',
  'mxxii',
  'mxxvi',
  'mxxxi',
  'nabla',
  'nabli',
  'nahua',
  'naibi',
  'naide',
  'naidi',
  'naira',
  'nakfa',
  'nanfa',
  'nanfe',
  'nanna',
  'nanne',
  'nante',
  'nanzi',
  'napea',
  'napee',
  'nappe',
  'nappi',
  'nappo',
  'narco',
  'nardi',
  'nardo',
  'nasai',
  'nasca',
  'nasci',
  'nashi',
  'naspi',
  'naspo',
  'nassa',
  'nasse',
  'nassi',
  'nasso',
  'nasua',
  'nasue',
  'natai',
  'natia',
  'natie',
  'natii',
  'natio',
  'natta',
  'natte',
  'nauta',
  'nauti',
  'ndoga',
  'ndoge',
  'ndogi',
  'ndogo',
  'nebbi',
  'necai',
  'necce',
  'necci',
  'nechi',
  'nembi',
  'nembo',
  'nemea',
  'nemee',
  'nemei',
  'nemeo',
  'nence',
  'nenci',
  'nenia',
  'nenie',
  'nerba',
  'nerbi',
  'nerbo',
  'nesce',
  'nesci',
  'nessi',
  'nesso',
  'nesta',
  'nesti',
  'nesto',
  'netta',
  'nette',
  'netti',
  'netto',
  'neuma',
  'neumi',
  'neura',
  'neure',
  'neuri',
  'neuro',
  'ngala',
  'nguni',
  'nibbi',
  'nicce',
  'nicci',
  'niego',
  'nievi',
  'nievo',
  'nimbi',
  'nimbo',
  'ninja',
  'ninna',
  'ninne',
  'ninni',
  'ninno',
  'niobi',
  'nisba',
  'nitra',
  'nitri',
  'nitro',
  'niuna',
  'niune',
  'niuni',
  'niuno',
  'nivea',
  'nivee',
  'nivei',
  'niveo',
  'nocca',
  'noema',
  'noemi',
  'noesi',
  'nogai',
  'noghi',
  'nomai',
  'nomea',
  'nomee',
  'nonio',
  'noria',
  'norie',
  'normi',
  'normo',
  'norna',
  'norne',
  'notta',
  'notto',
  'novai',
  'novio',
  'nudai',
  'nugae',
  'nulle',
  'nulli',
  'nummi',
  'nummo',
  'nunci',
  'nuoce',
  'nuoci',
  'nurse',
  'nutka',
  'nutra',
  'nutro',
  'nyala',
  'obbli',
  'obeli',
  'obelo',
  'obesa',
  'obese',
  'obesi',
  'obeso',
  'obice',
  'obici',
  'obiti',
  'obito',
  'oblai',
  'oboli',
  'obolo',
  'ocche',
  'ocimi',
  'ocimo',
  'ocrea',
  'ocree',
  'oculi',
  'oculo',
  'odano',
  'oddio',
  'odici',
  'odila',
  'odile',
  'odili',
  'odilo',
  'odimi',
  'odine',
  'odire',
  'odita',
  'odite',
  'oditi',
  'odito',
  'odiva',
  'odivi',
  'odivo',
  'odrai',
  'oecio',
  'ofide',
  'ofidi',
  'ofite',
  'ofiti',
  'ogiva',
  'ogive',
  'oiana',
  'oidio',
  'okapi',
  'oleai',
  'olfai',
  'olire',
  'olita',
  'olite',
  'oliti',
  'olito',
  'olivi',
  'olivo',
  'olmio',
  'olona',
  'olone',
  'olora',
  'olore',
  'olori',
  'oloro',
  'oltra',
  'omaha',
  'omani',
  'omano',
  'omari',
  'omaro',
  'omasi',
  'omaso',
  'ombri',
  'ombro',
  'omega',
  'omese',
  'omesi',
  'omini',
  'omino',
  'omise',
  'omisi',
  'omnia',
  'omone',
  'omoni',
  'ondai',
  'onera',
  'onere',
  'onero',
  'onese',
  'onesi',
  'onici',
  'onrai',
  'opaci',
  'opale',
  'opali',
  'opata',
  'opica',
  'opici',
  'opico',
  'opima',
  'opime',
  'opimi',
  'opimo',
  'oppia',
  'oraci',
  'orala',
  'oralo',
  'orami',
  'orane',
  'orano',
  'orare',
  'orata',
  'orate',
  'orati',
  'orato',
  'orava',
  'oravi',
  'oravo',
  'orbai',
  'orche',
  'orcio',
  'orfea',
  'orfee',
  'orgia',
  'oribi',
  'orice',
  'orici',
  'orige',
  'origi',
  'orina',
  'orine',
  'orini',
  'orino',
  'orobi',
  'oroci',
  'oroki',
  'oroma',
  'orome',
  'oromi',
  'oromo',
  'orrea',
  'orree',
  'orrei',
  'orreo',
  'orrii',
  'orzai',
  'osaci',
  'osage',
  'osagi',
  'osala',
  'osale',
  'osali',
  'osalo',
  'osami',
  'osane',
  'osche',
  'osmio',
  'ossea',
  'ossee',
  'ossei',
  'osseo',
  'ossia',
  'ostai',
  'osteo',
  'ostia',
  'ostie',
  'ostio',
  'ostri',
  'ostro',
  'otica',
  'otici',
  'otico',
  'otide',
  'otidi',
  'otite',
  'otiti',
  'ottri',
  'ovaia',
  'ovaie',
  'ovaio',
  'ovari',
  'ovata',
  'ovate',
  'ovati',
  'ovato',
  'ovina',
  'ovine',
  'ovini',
  'ovino',
  'ovoli',
  'ovolo',
  'ovula',
  'ovuli',
  'ovulo',
  'ovvie',
  'ovvii',
  'oyana',
  'ozena',
  'ozene',
  'ozoni',
  'ozono',
  'pabbi',
  'pacai',
  'pacca',
  'pachi',
  'pacia',
  'pacio',
  'pacta',
  'padda',
  'pafia',
  'pafie',
  'pafii',
  'pafio',
  'paggi',
  'paghe',
  'pagri',
  'pagro',
  'paini',
  'paino',
  'paisa',
  'palai',
  'palea',
  'palee',
  'palei',
  'paleo',
  'palio',
  'palli',
  'pallo',
  'palmi',
  'palmo',
  'palta',
  'palte',
  'pampa',
  'panai',
  'panda',
  'pande',
  'pandi',
  'pando',
  'pania',
  'panie',
  'pansa',
  'panse',
  'pansi',
  'panso',
  'panza',
  'panze',
  'paoli',
  'paolo',
  'pappi',
  'pappo',
  'papua',
  'parai',
  'parca',
  'parce',
  'parci',
  'pardi',
  'pardo',
  'parei',
  'pareo',
  'paria',
  'parie',
  'parii',
  'pario',
  'parka',
  'parma',
  'parme',
  'parra',
  'parre',
  'parse',
  'parsi',
  'parta',
  'parva',
  'parvi',
  'parvo',
  'pasca',
  'pasce',
  'pasci',
  'pasco',
  'passe',
  'patia',
  'patie',
  'patii',
  'patio',
  'patre',
  'patri',
  'patte',
  'pausi',
  'pauso',
  'pavia',
  'pavie',
  'peace',
  'peaci',
  'peana',
  'peani',
  'peata',
  'peate',
  'pecce',
  'pecci',
  'pechi',
  'pecia',
  'pecie',
  'pedio',
  'pekoe',
  'pelea',
  'pelee',
  'pelei',
  'peleo',
  'pelta',
  'pelte',
  'pelvi',
  'penei',
  'peneo',
  'penta',
  'pente',
  'penti',
  'pento',
  'peoci',
  'peone',
  'peoni',
  'peota',
  'peote',
  'pepai',
  'pepli',
  'peplo',
  'pepsi',
  'perca',
  'perda',
  'perdi',
  'perdo',
  'perii',
  'pervi',
  'pesta',
  'petai',
  'phone',
  'photo',
  'phyla',
  'piada',
  'piade',
  'piago',
  'piala',
  'piale',
  'piali',
  'pialo',
  'piami',
  'piamo',
  'piare',
  'piata',
  'piate',
  'piati',
  'piato',
  'piava',
  'piavi',
  'piavo',
  'picca',
  'picce',
  'picea',
  'picee',
  'picei',
  'piceo',
  'piche',
  'pichi',
  'picio',
  'piego',
  'pieri',
  'piese',
  'piesi',
  'pieta',
  'piete',
  'pieve',
  'pievi',
  'pighi',
  'pigia',
  'pigii',
  'pigio',
  'pigna',
  'pigne',
  'piino',
  'pilai',
  'pilei',
  'pileo',
  'pilla',
  'pilli',
  'pillo',
  'pinai',
  'pinca',
  'pince',
  'pinco',
  'pinea',
  'pinee',
  'pinei',
  'pineo',
  'pinsa',
  'pinse',
  'pinti',
  'pinto',
  'pinzi',
  'pinzo',
  'pioda',
  'piode',
  'piola',
  'piole',
  'pioli',
  'piolo',
  'pione',
  'pioni',
  'piota',
  'piote',
  'pioti',
  'pioto',
  'piova',
  'piovi',
  'piovo',
  'pipai',
  'pipia',
  'pipio',
  'pippa',
  'pippe',
  'pippi',
  'pippo',
  'pipra',
  'pipre',
  'pirla',
  'pirli',
  'pirlo',
  'pisce',
  'pisci',
  'pissi',
  'pitia',
  'pitie',
  'pitta',
  'pitte',
  'pitti',
  'pitto',
  'piumi',
  'piumo',
  'pizia',
  'pizie',
  'pizio',
  'placa',
  'place',
  'placo',
  'plaga',
  'plagi',
  'plana',
  'plani',
  'plano',
  'plica',
  'ploia',
  'ploie',
  'plora',
  'plori',
  'ploro',
  'ploti',
  'ploto',
  'plugo',
  'pluvi',
  'pocci',
  'podza',
  'podze',
  'podzi',
  'podzo',
  'poeto',
  'pogai',
  'pogge',
  'poghi',
  'poise',
  'polca',
  'polii',
  'polio',
  'polka',
  'polke',
  'polla',
  'polle',
  'polpi',
  'polpo',
  'polta',
  'polte',
  'polve',
  'polvi',
  'pomai',
  'pomfi',
  'pomfo',
  'ponce',
  'ponci',
  'ponda',
  'pondi',
  'pondo',
  'ponfi',
  'ponfo',
  'ponga',
  'pongo',
  'ponta',
  'ponto',
  'ponza',
  'ponzi',
  'ponzo',
  'poppi',
  'poppo',
  'porca',
  'porci',
  'porco',
  'porga',
  'porgi',
  'porgo',
  'porla',
  'porle',
  'porli',
  'pormi',
  'porne',
  'porno',
  'porsi',
  'posca',
  'posse',
  'potai',
  'potta',
  'potte',
  'poule',
  'praho',
  'prana',
  'prasi',
  'prava',
  'prave',
  'pravi',
  'pravo',
  'prece',
  'preci',
  'preco',
  'preli',
  'previ',
  'price',
  'pride',
  'proba',
  'probe',
  'probi',
  'probo',
  'proci',
  'proco',
  'proda',
  'proli',
  'promo',
  'prona',
  'prone',
  'proni',
  'prono',
  'prora',
  'prore',
  'prosi',
  'proso',
  'proti',
  'proto',
  'prozi',
  'pruda',
  'prude',
  'prudi',
  'prudo',
  'pruna',
  'prune',
  'pruni',
  'pruno',
  'psico',
  'psoco',
  'psofi',
  'psofo',
  'ptini',
  'ptino',
  'ptosi',
  'pucci',
  'puffi',
  'puffo',
  'pugge',
  'puggi',
  'pugio',
  'pugna',
  'pugne',
  'puina',
  'puine',
  'pulca',
  'pulka',
  'pulke',
  'pulla',
  'pulle',
  'pulli',
  'pullo',
  'punci',
  'punga',
  'punii',
  'puppa',
  'puppi',
  'puppo',
  'purai',
  'purea',
  'puree',
  'pussa',
  'pussi',
  'pusso',
  'pusta',
  'puste',
  'putai',
  'putii',
  'putre',
  'putri',
  'putta',
  'putte',
  'putti',
  'putto',
  'qasba',
  'qasbe',
  'qibla',
  'quada',
  'quade',
  'quadi',
  'quado',
  'quare',
  'queta',
  'queto',
  'quici',
  'quija',
  'quije',
  'quiji',
  'quijo',
  'quina',
  'quine',
  'quini',
  'quino',
  'quita',
  'quiti',
  'quito',
  'quivi',
  'rabbi',
  'rabia',
  'racca',
  'racco',
  'racla',
  'racle',
  'raffa',
  'raffe',
  'raffi',
  'raffo',
  'rafia',
  'rafie',
  'raghe',
  'ragia',
  'ragie',
  'ragli',
  'ragna',
  'ragne',
  'raiai',
  'raide',
  'raidi',
  'raino',
  'raita',
  'raiti',
  'raito',
  'ralla',
  'ralle',
  'ralli',
  'rallo',
  'rambo',
  'ramea',
  'ramee',
  'ramei',
  'rameo',
  'ramia',
  'ramie',
  'ramni',
  'ramno',
  'rampi',
  'rampo',
  'ranca',
  'rance',
  'ranco',
  'randa',
  'rande',
  'ranfi',
  'range',
  'ranni',
  'ranno',
  'rappa',
  'rappe',
  'rasai',
  'rasce',
  'raspe',
  'rasta',
  'ratea',
  'ratei',
  'rateo',
  'ratio',
  'ratta',
  'ratte',
  'reame',
  'reami',
  'rebbi',
  'recei',
  'recto',
  'redai',
  'redii',
  'reese',
  'reesi',
  'regga',
  'regia',
  'regie',
  'regio',
  'reide',
  'reidi',
  'reiki',
  'renai',
  'renio',
  'rensa',
  'rense',
  'repli',
  'replo',
  'requi',
  'resca',
  'resia',
  'resie',
  'respi',
  'respo',
  'reste',
  'retai',
  'retea',
  'retee',
  'retei',
  'reteo',
  'retri',
  'reuma',
  'reumi',
  'rezza',
  'rezze',
  'rezzi',
  'rezzo',
  'riale',
  'riali',
  'riama',
  'riami',
  'riamo',
  'riara',
  'riari',
  'riaro',
  'ricce',
  'ridai',
  'ridda',
  'ridde',
  'riddi',
  'riddo',
  'ridia',
  'rieda',
  'riede',
  'riedi',
  'riedo',
  'riera',
  'rieri',
  'riero',
  'rifai',
  'riffa',
  'riffe',
  'rifui',
  'rigai',
  'rigge',
  'righi',
  'rigna',
  'rigni',
  'rigno',
  'rimai',
  'rioca',
  'rioco',
  'rioda',
  'riode',
  'riodi',
  'riodo',
  'ripii',
  'risai',
  'risei',
  'risia',
  'risii',
  'rispi',
  'rissi',
  'risso',
  'ritma',
  'riusa',
  'riusi',
  'riuso',
  'rivai',
  'rizza',
  'rizze',
  'rizzi',
  'rizzo',
  'roana',
  'roane',
  'roani',
  'roano',
  'robbi',
  'rocca',
  'rocco',
  'roche',
  'rochi',
  'rodai',
  'rodei',
  'rodeo',
  'rodia',
  'rodie',
  'rodii',
  'rodio',
  'roese',
  'roesi',
  'rogai',
  'rogge',
  'roggi',
  'rogna',
  'rogne',
  'rogni',
  'rogno',
  'rolla',
  'rolli',
  'rollo',
  'rombe',
  'romea',
  'romee',
  'romei',
  'romeo',
  'ronca',
  'ronco',
  'rondi',
  'rondo',
  'ronfa',
  'ronfe',
  'ronfi',
  'ronfo',
  'rorai',
  'rosai',
  'rosea',
  'rosee',
  'rosei',
  'roseo',
  'rosta',
  'roste',
  'rosti',
  'rosto',
  'rotai',
  'rotea',
  'rotei',
  'roteo',
  'rouge',
  'rovai',
  'rubbi',
  'rubbo',
  'rubea',
  'rubee',
  'rubei',
  'rubeo',
  'rubli',
  'rublo',
  'rubra',
  'rubre',
  'rubri',
  'rubro',
  'ruche',
  'ruese',
  'ruesi',
  'ruffa',
  'ruffe',
  'rugai',
  'rughi',
  'rugli',
  'ruina',
  'ruini',
  'ruino',
  'rumai',
  'rumba',
  'rumbe',
  'rumme',
  'rummi',
  'ruoti',
  'ruoto',
  'rupia',
  'rupie',
  'ruppi',
  'rusca',
  'rusco',
  'ruspa',
  'ruspe',
  'ruspi',
  'ruspo',
  'russe',
  'rutta',
  'rutti',
  'rutto',
  'ruzza',
  'ruzze',
  'ruzzi',
  'ruzzo',
  'saale',
  'saali',
  'sabba',
  'sabbi',
  'sabea',
  'sabee',
  'sabei',
  'sabeo',
  'sabia',
  'sabie',
  'sabii',
  'sabio',
  'sabra',
  'sacia',
  'sacie',
  'sacio',
  'saffo',
  'saghe',
  'saghi',
  'sagii',
  'sagna',
  'sagni',
  'sagno',
  'sagra',
  'sagre',
  'sagri',
  'sagro',
  'saiga',
  'saime',
  'saimi',
  'salgo',
  'salii',
  'salio',
  'salpe',
  'salsi',
  'salso',
  'samba',
  'samia',
  'samie',
  'samii',
  'samio',
  'sanie',
  'sansa',
  'sanse',
  'saora',
  'sapio',
  'sarda',
  'sarde',
  'sardi',
  'sardo',
  'sarge',
  'sargo',
  'sauna',
  'saune',
  'saura',
  'saure',
  'sauri',
  'sauro',
  'savia',
  'savie',
  'savio',
  'sbafa',
  'sbafi',
  'sbafo',
  'sboba',
  'sbobe',
  'sbora',
  'sbore',
  'sbovi',
  'sbovo',
  'sbuca',
  'sbuco',
  'scafa',
  'scafe',
  'scana',
  'scane',
  'scapa',
  'scapi',
  'scapo',
  'scari',
  'scaro',
  'scasa',
  'scasi',
  'scaso',
  'sceda',
  'scede',
  'schei',
  'schwa',
  'sciai',
  'scifi',
  'scifo',
  'scipa',
  'scipi',
  'scipo',
  'scira',
  'scire',
  'sciri',
  'sciro',
  'scita',
  'scite',
  'sciti',
  'scoda',
  'scodi',
  'scodo',
  'scoia',
  'scoio',
  'scola',
  'scora',
  'score',
  'scori',
  'scoro',
  'scota',
  'scote',
  'scoti',
  'scoto',
  'scuce',
  'scuci',
  'scuda',
  'scuoi',
  'scuti',
  'scuto',
  'sdama',
  'sdami',
  'sdamo',
  'sdare',
  'sdata',
  'sdate',
  'sdati',
  'sdato',
  'sdava',
  'sdavi',
  'sdavo',
  'sdazi',
  'sdica',
  'sdice',
  'sdici',
  'sdico',
  'sdire',
  'sdite',
  'sdoga',
  'sdogo',
  'sdora',
  'sdori',
  'sdoro',
  'sdrai',
  'sduca',
  'sduco',
  'secai',
  'secce',
  'sechi',
  'sedei',
  'sedio',
  'segai',
  'segga',
  'seggo',
  'seghi',
  'seini',
  'seino',
  'selce',
  'selfa',
  'selfi',
  'selfo',
  'selli',
  'sello',
  'semai',
  'senna',
  'senne',
  'senni',
  'senoi',
  'sepsi',
  'serbe',
  'sermo',
  'serpa',
  'serpe',
  'serpi',
  'serpo',
  'serti',
  'serto',
  'sesia',
  'sesie',
  'sessa',
  'sesse',
  'sesta',
  'seste',
  'sesti',
  'sesto',
  'setti',
  'setto',
  'sezza',
  'sezze',
  'sezzi',
  'sezzo',
  'sfare',
  'sfasa',
  'sfasi',
  'sfaso',
  'sfata',
  'sfate',
  'sfati',
  'sfato',
  'sfece',
  'sfeci',
  'sfiga',
  'sfina',
  'sfini',
  'sfino',
  'sfizi',
  'sfoca',
  'sfoco',
  'sfora',
  'sfori',
  'sforo',
  'sfuma',
  'sfumi',
  'sfumo',
  'sfuri',
  'sfusa',
  'sfuse',
  'sfusi',
  'sfuso',
  'sgama',
  'sgami',
  'sgamo',
  'sgara',
  'sgari',
  'sgaro',
  'sgasa',
  'sgasi',
  'sgaso',
  'sgela',
  'sgeli',
  'sgola',
  'sgoli',
  'sgolo',
  'sgura',
  'sguri',
  'sguro',
  'shake',
  'shara',
  'share',
  'shari',
  'sharo',
  'shina',
  'shire',
  'siche',
  'sicli',
  'siclo',
  'sidri',
  'siepa',
  'siepo',
  'sigli',
  'siglo',
  'sigma',
  'sigmi',
  'sigua',
  'siici',
  'siila',
  'siile',
  'siili',
  'siilo',
  'siimi',
  'siine',
  'siiti',
  'silfi',
  'silfo',
  'silli',
  'sillo',
  'silvi',
  'sinti',
  'sipai',
  'sirma',
  'sirme',
  'sirte',
  'sirti',
  'sisma',
  'sismi',
  'sismo',
  'sitai',
  'sitii',
  'sizio',
  'sizza',
  'sizze',
  'skate',
  'skypa',
  'skypi',
  'skypo',
  'slama',
  'slami',
  'slamo',
  'slava',
  'slave',
  'slavi',
  'slavo',
  'slebi',
  'slebo',
  'slice',
  'slide',
  'sluma',
  'slumi',
  'slumo',
  'smaga',
  'smagi',
  'smago',
  'smani',
  'smela',
  'smeli',
  'smelo',
  'smera',
  'smeri',
  'smero',
  'smile',
  'smina',
  'smini',
  'smino',
  'smira',
  'smiri',
  'smiro',
  'smoda',
  'smodi',
  'smodo',
  'smoke',
  'smova',
  'smove',
  'smovi',
  'smovo',
  'smura',
  'smuri',
  'smuro',
  'smusa',
  'smusi',
  'smuso',
  'snasa',
  'snasi',
  'snaso',
  'sneva',
  'snevi',
  'snevo',
  'snida',
  'snidi',
  'snido',
  'snipe',
  'snuda',
  'snudi',
  'snudo',
  'soana',
  'soane',
  'soani',
  'soano',
  'socci',
  'socco',
  'socia',
  'socie',
  'sodai',
  'sofia',
  'sofie',
  'soghe',
  'sogli',
  'solda',
  'soldi',
  'soldo',
  'solea',
  'solee',
  'solei',
  'soleo',
  'solfa',
  'solfe',
  'solfi',
  'solfo',
  'solgo',
  'solio',
  'solla',
  'solle',
  'solli',
  'sollo',
  'solta',
  'solte',
  'solti',
  'solto',
  'solva',
  'solve',
  'solvi',
  'solvo',
  'somni',
  'sonai',
  'sonco',
  'songa',
  'songe',
  'songi',
  'songo',
  'sonii',
  'sonio',
  'sorai',
  'sorba',
  'sorbe',
  'sorbi',
  'sorbo',
  'sorca',
  'sorga',
  'sorgi',
  'sorgo',
  'sorra',
  'sorre',
  'sorsa',
  'sotho',
  'sotta',
  'sotti',
  'sovra',
  'sozio',
  'spaia',
  'spaio',
  'spala',
  'spali',
  'spalo',
  'spana',
  'spani',
  'spano',
  'spasa',
  'spase',
  'spasi',
  'spaso',
  'spata',
  'spate',
  'spati',
  'spato',
  'speco',
  'speda',
  'spedi',
  'spedo',
  'spela',
  'speli',
  'spelo',
  'speme',
  'spemi',
  'spene',
  'speni',
  'spepa',
  'spepe',
  'spere',
  'spezi',
  'spiai',
  'spica',
  'spigo',
  'spike',
  'spini',
  'spino',
  'spire',
  'spone',
  'sponi',
  'spora',
  'spore',
  'sprue',
  'spula',
  'spuli',
  'spulo',
  'spumi',
  'spumo',
  'spura',
  'spuri',
  'spuro',
  'stage',
  'staia',
  'staio',
  'stame',
  'stami',
  'stara',
  'stari',
  'stasa',
  'stasi',
  'staso',
  'steca',
  'stele',
  'steno',
  'steri',
  'stero',
  'stibi',
  'stici',
  'stico',
  'stiga',
  'stigi',
  'stigo',
  'stila',
  'stilo',
  'stina',
  'stini',
  'stino',
  'stipa',
  'stipe',
  'stipi',
  'stipo',
  'stoga',
  'stogo',
  'stoia',
  'stoio',
  'stoma',
  'stomi',
  'stone',
  'store',
  'stori',
  'stria',
  'strie',
  'strio',
  'sture',
  'stuta',
  'stuti',
  'stuto',
  'style',
  'suari',
  'suave',
  'suavi',
  'subbi',
  'subii',
  'succi',
  'suchi',
  'sugai',
  'sugga',
  'sugge',
  'suggi',
  'suggo',
  'sughi',
  'sugna',
  'sugne',
  'suide',
  'suidi',
  'suina',
  'suine',
  'suite',
  'sulka',
  'summa',
  'summe',
  'sunna',
  'sunne',
  'sunti',
  'suoli',
  'suolo',
  'surfa',
  'surfi',
  'surfo',
  'surga',
  'surge',
  'surgi',
  'surgo',
  'surma',
  'surra',
  'surre',
  'surse',
  'sursi',
  'surta',
  'surte',
  'surti',
  'surto',
  'susci',
  'sushi',
  'sussi',
  'susta',
  'suste',
  'sutha',
  'suthe',
  'suthi',
  'sutho',
  'sutra',
  'sutri',
  'sutro',
  'suzza',
  'suzzi',
  'suzzo',
  'svani',
  'svapa',
  'svapi',
  'svapo',
  'svari',
  'svasa',
  'svasi',
  'svaso',
  'svela',
  'sveli',
  'svelo',
  'svena',
  'sveni',
  'sveno',
  'sveva',
  'sveve',
  'svevi',
  'svevo',
  'svina',
  'svini',
  'sviva',
  'svivi',
  'svivo',
  'svizi',
  'svola',
  'svoli',
  'svolo',
  'svota',
  'svoti',
  'svoto',
  'svuoi',
  'swazi',
  'tabla',
  'tacca',
  'tacce',
  'taffi',
  'tafia',
  'tafie',
  'tafii',
  'tafio',
  'tagga',
  'taggo',
  'taide',
  'taidi',
  'taiga',
  'taiko',
  'taina',
  'taine',
  'taini',
  'taino',
  'talea',
  'talee',
  'talli',
  'tallo',
  'tamia',
  'tanai',
  'tanca',
  'tanco',
  'tanfa',
  'tanfi',
  'tange',
  'tango',
  'tanna',
  'tanni',
  'tanno',
  'tarai',
  'tarde',
  'targo',
  'tarla',
  'tarli',
  'tarlo',
  'tarma',
  'tarme',
  'tarmi',
  'tarmo',
  'tarsi',
  'tarso',
  'taste',
  'taura',
  'taure',
  'tauri',
  'tauro',
  'tebea',
  'tebee',
  'tebei',
  'tebeo',
  'tecca',
  'teche',
  'tecno',
  'tegea',
  'tegee',
  'tegei',
  'tegeo',
  'teghe',
  'teide',
  'teidi',
  'teina',
  'teine',
  'teita',
  'teite',
  'teiti',
  'temei',
  'tempa',
  'tempe',
  'tendi',
  'tendo',
  'tenei',
  'tenge',
  'tenno',
  'tensa',
  'tense',
  'tenza',
  'tenze',
  'tepee',
  'teppa',
  'teppe',
  'terbi',
  'termo',
  'terni',
  'terno',
  'terza',
  'terze',
  'terzi',
  'tesai',
  'tesla',
  'tesle',
  'tetta',
  'texta',
  'texte',
  'texti',
  'texto',
  'theta',
  'tiade',
  'tiadi',
  'tiara',
  'tiare',
  'tiasi',
  'tiaso',
  'tifai',
  'tigni',
  'tigno',
  'tigra',
  'tigro',
  'tigua',
  'tilde',
  'tildi',
  'tilla',
  'tille',
  'tilli',
  'tillo',
  'timpa',
  'timpe',
  'tinca',
  'tinga',
  'tinge',
  'tingi',
  'tingo',
  'tinse',
  'tinsi',
  'tioli',
  'tiolo',
  'tirsi',
  'tirso',
  'tizzi',
  'tizzo',
  'tmesi',
  'tocai',
  'tochi',
  'toghi',
  'tokai',
  'tolda',
  'tolde',
  'tolla',
  'tolle',
  'tomai',
  'tombi',
  'tombo',
  'tonai',
  'tonfa',
  'tonia',
  'tonie',
  'topoi',
  'toppi',
  'toppo',
  'toque',
  'torbi',
  'torbo',
  'torca',
  'torci',
  'torco',
  'tordi',
  'tordo',
  'torea',
  'torei',
  'toreo',
  'torio',
  'torli',
  'torlo',
  'torma',
  'torme',
  'torse',
  'torsi',
  'torso',
  'torva',
  'torve',
  'torvi',
  'torvo',
  'tosca',
  'tosco',
  'tossa',
  'tosso',
  'trace',
  'traci',
  'trade',
  'trago',
  'trana',
  'trani',
  'trano',
  'trapa',
  'trape',
  'trare',
  'trena',
  'trevi',
  'trevo',
  'triai',
  'tribi',
  'tribo',
  'triga',
  'trike',
  'trina',
  'trine',
  'trini',
  'trino',
  'trivi',
  'troco',
  'troia',
  'troie',
  'trona',
  'trone',
  'tropi',
  'tropo',
  'truca',
  'truce',
  'truci',
  'truka',
  'truke',
  'tsuga',
  'tufai',
  'tughe',
  'tulio',
  'tumba',
  'tunna',
  'tunne',
  'tunni',
  'tunno',
  'turbe',
  'turbi',
  'turca',
  'turco',
  'turma',
  'turme',
  'turna',
  'turpe',
  'turpi',
  'tusca',
  'tusco',
  'tutai',
  'tutsi',
  'tuzia',
  'tuzie',
  'ubbia',
  'ubbie',
  'ubera',
  'ubere',
  'uberi',
  'ubero',
  'ubica',
  'ubico',
  'ubini',
  'ubino',
  'uccia',
  'uccio',
  'udivi',
  'udrai',
  'udrei',
  'ufizi',
  'uggia',
  'uggii',
  'uggio',
  'ugnai',
  'ugola',
  'ugole',
  'ukase',
  'ukiyo',
  'ulama',
  'ulani',
  'ulano',
  'ulema',
  'ulese',
  'ulesi',
  'ulice',
  'ulici',
  'ulite',
  'uliti',
  'uliva',
  'ulive',
  'ulivi',
  'ulivo',
  'ultra',
  'ulule',
  'umata',
  'umate',
  'umati',
  'umato',
  'umbra',
  'umbre',
  'umbri',
  'umbro',
  'umica',
  'umici',
  'umico',
  'unari',
  'unqua',
  'unque',
  'untai',
  'upupa',
  'upupe',
  'urali',
  'urati',
  'urato',
  'urese',
  'uresi',
  'urica',
  'urici',
  'urico',
  'urina',
  'urine',
  'urini',
  'urino',
  'urlii',
  'urlio',
  'urone',
  'uroni',
  'urtai',
  'usaci',
  'usala',
  'usale',
  'usali',
  'usalo',
  'usami',
  'usane',
  'usuri',
  'usuro',
  'uteri',
  'utero',
  'utese',
  'utesi',
  'uvala',
  'uvale',
  'uvina',
  'uvine',
  'uvola',
  'uvole',
  'uvosa',
  'uvose',
  'uvosi',
  'uvoso',
  'uvula',
  'uvule',
  'uxori',
  'vacai',
  'vacci',
  'vachi',
  'vacua',
  'vacue',
  'vacui',
  'vacuo',
  'vaffa',
  'vagai',
  'vagii',
  'vaiai',
  'valla',
  'valsa',
  'valse',
  'valsi',
  'valso',
  'valva',
  'valve',
  'vammi',
  'vampa',
  'vampe',
  'vampo',
  'vanai',
  'vanea',
  'vanei',
  'vaneo',
  'vania',
  'vanie',
  'vanii',
  'vanio',
  'vanne',
  'vanni',
  'varai',
  'varca',
  'varea',
  'varee',
  'varva',
  'varve',
  'vasai',
  'vatti',
  'vauda',
  'vaude',
  'vecce',
  'vecia',
  'vecio',
  'vedda',
  'vegge',
  'veggi',
  'velia',
  'velie',
  'velma',
  'velme',
  'venai',
  'vengi',
  'vengo',
  'venia',
  'venie',
  'venta',
  'vepre',
  'vepri',
  'vepsa',
  'vepse',
  'vepsi',
  'vepso',
  'verge',
  'vergi',
  'vergo',
  'verla',
  'verle',
  'vermo',
  'verna',
  'verne',
  'verni',
  'verno',
  'verri',
  'verro',
  'verse',
  'verta',
  'verti',
  'verto',
  'verve',
  'verza',
  'verze',
  'vesce',
  'vesta',
  'vetro',
  'viado',
  'viari',
  'vichi',
  'viera',
  'viere',
  'vieri',
  'viero',
  'viete',
  'vigli',
  'vilai',
  'vilii',
  'villi',
  'villo',
  'vimai',
  'vinca',
  'vinea',
  'vinee',
  'vinse',
  'vinsi',
  'vipla',
  'viple',
  'virai',
  'virei',
  'vireo',
  'virga',
  'virgo',
  'visai',
  'visco',
  'vitae',
  'vitai',
  'vitro',
  'vitta',
  'vitte',
  'vivai',
  'vizza',
  'vizze',
  'vizzi',
  'vizzo',
  'vocai',
  'vochi',
  'vocia',
  'vocii',
  'vocio',
  'vodka',
  'voghe',
  'vogli',
  'voice',
  'voile',
  'volca',
  'volco',
  'volee',
  'volga',
  'volpa',
  'volpo',
  'volsi',
  'volva',
  'volve',
  'volvi',
  'volvo',
  'vomii',
  'vorai',
  'vosco',
  'votai',
  'votii',
  'vulga',
  'vulgo',
  'vulva',
  'vulve',
  'wakhi',
  'wasca',
  'wasce',
  'wasci',
  'wasco',
  'washa',
  'washe',
  'washi',
  'washo',
  'wayaa',
  'wayae',
  'wayai',
  'wayao',
  'white',
  'whiti',
  'widia',
  'xciii',
  'xcvii',
  'xenia',
  'xenie',
  'xhosa',
  'xliii',
  'xlvii',
  'xviii',
  'xxiii',
  'xxvii',
  'xxxii',
  'xxxvi',
  'yagua',
  'yamea',
  'yamee',
  'yamei',
  'yameo',
  'yaqui',
  'yoghi',
  'yucca',
  'yuchi',
  'yurta',
  'yurte',
  'zabri',
  'zabro',
  'zaffa',
  'zaffe',
  'zaffi',
  'zaffo',
  'zaina',
  'zaine',
  'zaire',
  'zamba',
  'zambo',
  'zamia',
  'zamie',
  'zampi',
  'zampo',
  'zanca',
  'zande',
  'zanni',
  'zanno',
  'zappe',
  'zarri',
  'zarro',
  'zatta',
  'zatte',
  'zebra',
  'zebre',
  'zebri',
  'zebro',
  'zecco',
  'zelai',
  'zenda',
  'zende',
  'zendi',
  'zendo',
  'zerbi',
  'zerbo',
  'zerga',
  'zerge',
  'zergi',
  'zergo',
  'zetti',
  'zetto',
  'zifio',
  'zigai',
  'zighi',
  'zigna',
  'zigni',
  'zigno',
  'zilla',
  'zilli',
  'zillo',
  'zinca',
  'zinco',
  'zinia',
  'zinie',
  'zinna',
  'zinne',
  'zinni',
  'zinno',
  'ziona',
  'zione',
  'zioni',
  'zippa',
  'zippi',
  'zippo',
  'zirbi',
  'zirbo',
  'zirla',
  'zirli',
  'zirlo',
  'zizza',
  'zizze',
  'zoeci',
  'zolfa',
  'zolfi',
  'zolli',
  'zollo',
  'zomba',
  'zombi',
  'zombo',
  'zompa',
  'zompi',
  'zompo',
  'zonai',
  'zonzo',
  'zooma',
  'zoomi',
  'zoomo',
  'zoque',
  'zoqui',
  'zozza',
  'zozze',
  'zozzi',
  'zozzo',
  'zuava',
  'zuave',
  'zuavi',
  'zuavo',
  'zughi',
  'zumai',
  'zuppi',
  'zuppo',
  'zurla',
  'zurli',
  'zurlo',
]
