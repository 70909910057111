import React from 'react'
import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  React.useEffect(() => {
    if (!localStorage.firstAccess) {
      localStorage.firstAccess = new Date().toJSON()
    }
  }, [])

  return (
    <BaseModal title="Come si gioca" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Indovina la parola in 6 tentativi. Dopo ogni tentativo, i colori delle
        caselle cambieranno per indicarti quanto ci sei andato vicino.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" status="correct" />
        <Cell value="A" />
        <Cell value="E" />
        <Cell value="S" />
        <Cell value="E" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La lettera P è nella parola ed è nel posto giusto.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="M" />
        <Cell value="A" />
        <Cell value="D" status="present" />
        <Cell value="R" />
        <Cell value="E" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La lettera D è nella parola ma è nel posto sbagliato.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="G" />
        <Cell value="I" />
        <Cell value="O" />
        <Cell value="C" status="absent" />
        <Cell value="O" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La lettera C non è nella parola.
      </p>
    </BaseModal>
  )
}
