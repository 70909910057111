export const WORDS = [
  'riavi',
  'ulula',
  'pensi',
  'vieti',
  'dirvi',
  'dovra',
  'umana',
  'cigli',
  'osino',
  'scudo',
  'lutti',
  'cicli',
  'aravo',
  'nelle',
  'ciste',
  'salme',
  'sorge',
  'avide',
  'danzo',
  'scado',
  'gioii',
  'esimo',
  'dalle',
  'potei',
  'culli',
  'forai',
  'slega',
  'spara',
  'morii',
  'capre',
  'atomi',
  'magre',
  'cosce',
  'retti',
  'cacao',
  'pieni',
  'corde',
  'danni',
  'terna',
  'primo',
  'gobbe',
  'pegni',
  'nafta',
  'frate',
  'balla',
  'zuffe',
  'forse',
  'avida',
  'merli',
  'trave',
  'paure',
  'ruote',
  'casta',
  'stive',
  'brevi',
  'aridi',
  'preso',
  'pesci',
  'folla',
  'video',
  'ormai',
  'elogi',
  'furto',
  'abate',
  'fiori',
  'bozza',
  'digli',
  'conio',
  'rette',
  'conti',
  'pizza',
  'medio',
  'oncia',
  'cauta',
  'salta',
  'zeppe',
  'offri',
  'suono',
  'belli',
  'gocce',
  'calvo',
  'vigna',
  'esami',
  'medie',
  'abile',
  'batte',
  'puzzo',
  'detta',
  'persa',
  'ritto',
  'bagno',
  'tirai',
  'stesa',
  'monta',
  'borsa',
  'fosse',
  'saldi',
  'pieno',
  'vesto',
  'crude',
  'aliti',
  'radia',
  'spari',
  'pazze',
  'avare',
  'stira',
  'giace',
  'stavo',
  'farti',
  'amare',
  'chine',
  'porta',
  'sacre',
  'nello',
  'beata',
  'ronza',
  'situi',
  'botte',
  'brodo',
  'messi',
  'amate',
  'lesto',
  'amato',
  'dolci',
  'zolle',
  'circa',
  'parli',
  'nuovo',
  'asole',
  'parla',
  'ombra',
  'lodai',
  'ruota',
  'imita',
  'amica',
  'colli',
  'larga',
  'debba',
  'attui',
  'abito',
  'spiri',
  'disse',
  'togli',
  'tramo',
  'vegli',
  'pugni',
  'frodi',
  'erede',
  'tende',
  'furbo',
  'fitti',
  'darai',
  'vanta',
  'terra',
  'legno',
  'elusa',
  'acido',
  'lotta',
  'muggi',
  'opaco',
  'tedia',
  'miste',
  'tolta',
  'sorda',
  'demmo',
  'scafo',
  'bieco',
  'sturo',
  'zitti',
  'rocce',
  'nause',
  'retta',
  'istmo',
  'ardue',
  'brava',
  'zaini',
  'zitta',
  'ressi',
  'tenui',
  'levai',
  'alito',
  'brine',
  'patti',
  'volse',
  'rubai',
  'letti',
  'barba',
  'fonda',
  'hanno',
  'massa',
  'carte',
  'busti',
  'tante',
  'epuri',
  'vaste',
  'osavo',
  'riava',
  'afosi',
  'tesse',
  'spuma',
  'elusi',
  'pacco',
  'zappo',
  'linde',
  'sfama',
  'orchi',
  'caffe',
  'costa',
  'sorso',
  'suini',
  'diedi',
  'vinta',
  'sorte',
  'nuota',
  'dighe',
  'rombo',
  'avvio',
  'bulbi',
  'esule',
  'batti',
  'punto',
  'folta',
  'tarpa',
  'manco',
  'gelai',
  'sonno',
  'scovo',
  'suole',
  'punse',
  'mosca',
  'mozza',
  'seppe',
  'terge',
  'crani',
  'guadi',
  'animo',
  'cielo',
  'balza',
  'acute',
  'vuole',
  'tosse',
  'ebano',
  'usava',
  'umidi',
  'utili',
  'guidi',
  'ferii',
  'miete',
  'muoia',
  'atomo',
  'testo',
  'scada',
  'isole',
  'grida',
  'zuppe',
  'bordo',
  'falsi',
  'messe',
  'nutri',
  'nasce',
  'spole',
  'pulci',
  'torce',
  'sazie',
  'germe',
  'busso',
  'obera',
  'citta',
  'mieto',
  'palpi',
  'urlai',
  'falco',
  'aduni',
  'scemo',
  'tardi',
  'bruni',
  'opero',
  'esige',
  'tacco',
  'vizia',
  'tetre',
  'posta',
  'vario',
  'tuona',
  'panne',
  'aiuta',
  'stufi',
  'stato',
  'anime',
  'matti',
  'dirsi',
  'berro',
  'cozza',
  'finsi',
  'porvi',
  'calze',
  'causa',
  'amero',
  'guada',
  'slego',
  'volta',
  'ferma',
  'emana',
  'morsi',
  'falle',
  'secco',
  'doppi',
  'folto',
  'espii',
  'piaci',
  'rechi',
  'pulii',
  'rozzi',
  'muori',
  'porri',
  'tigli',
  'poeti',
  'colmo',
  'attuo',
  'pulce',
  'speso',
  'invia',
  'lembi',
  'negli',
  'mutai',
  'asina',
  'falde',
  'lista',
  'metta',
  'falda',
  'picco',
  'vasto',
  'stiri',
  'bosco',
  'metri',
  'rendo',
  'calzi',
  'farai',
  'vedra',
  'opini',
  'volto',
  'cadra',
  'vesti',
  'linee',
  'giova',
  'optai',
  'onice',
  'serve',
  'morti',
  'dotto',
  'rompo',
  'mosse',
  'tubai',
  'calci',
  'spume',
  'danno',
  'avaro',
  'mazza',
  'desse',
  'bruti',
  'fossa',
  'epico',
  'cessi',
  'bagni',
  'fichi',
  'esili',
  'tigne',
  'privo',
  'spiga',
  'bravi',
  'vissi',
  'polsi',
  'babbi',
  'selva',
  'mazze',
  'uniti',
  'volli',
  'abete',
  'etere',
  'resse',
  'cigno',
  'vorra',
  'sfera',
  'pigre',
  'rompi',
  'globi',
  'tuffa',
  'pinta',
  'pozza',
  'velli',
  'zoppa',
  'poggi',
  'laici',
  'fiuta',
  'tutta',
  'fosco',
  'tremo',
  'asini',
  'debbo',
  'corso',
  'burlo',
  'pende',
  'usate',
  'latte',
  'calmo',
  'oltre',
  'reggo',
  'giuro',
  'gradi',
  'messa',
  'tenne',
  'tasca',
  'crisi',
  'furbe',
  'fasce',
  'amata',
  'sfoga',
  'ebeti',
  'valli',
  'leste',
  'amano',
  'tremi',
  'tenia',
  'provi',
  'poema',
  'molti',
  'adora',
  'stili',
  'scusa',
  'sfido',
  'parte',
  'abusa',
  'dovro',
  'fetta',
  'tetto',
  'panna',
  'versi',
  'oliai',
  'lerce',
  'viali',
  'dotti',
  'casto',
  'umori',
  'sarta',
  'rospi',
  'fruga',
  'tieni',
  'univa',
  'bolli',
  'stufe',
  'resto',
  'buoni',
  'giara',
  'fiuto',
  'miopi',
  'salsa',
  'falci',
  'andai',
  'vuoto',
  'spose',
  'risse',
  'drago',
  'garbo',
  'stufo',
  'fiera',
  'dilui',
  'astro',
  'udivo',
  'norme',
  'canto',
  'pinne',
  'calmi',
  'sbavi',
  'piede',
  'sarei',
  'quoto',
  'molto',
  'losca',
  'sarto',
  'sulle',
  'bomba',
  'sorta',
  'segui',
  'torna',
  'campi',
  'tuoni',
  'lampo',
  'presa',
  'bussa',
  'stava',
  'siepe',
  'avevo',
  'mieta',
  'corno',
  'arida',
  'fiume',
  'lacci',
  'cesta',
  'aravi',
  'olive',
  'siete',
  'abuso',
  'amene',
  'senso',
  'tappe',
  'copia',
  'preti',
  'ragni',
  'tonfi',
  'osato',
  'diete',
  'arido',
  'tinto',
  'uscio',
  'volte',
  'pazza',
  'mozzi',
  'burri',
  'tappi',
  'punge',
  'agire',
  'mento',
  'aromi',
  'trite',
  'corto',
  'lindi',
  'agili',
  'campo',
  'lisci',
  'onori',
  'amati',
  'negre',
  'penne',
  'abati',
  'afona',
  'tutte',
  'addio',
  'abbai',
  'vigne',
  'luogo',
  'plico',
  'mesto',
  'darne',
  'arduo',
  'sposo',
  'tigna',
  'odora',
  'fungo',
  'giovi',
  'sigla',
  'gobbo',
  'brodi',
  'vogai',
  'pinze',
  'morto',
  'pompi',
  'rapai',
  'rullo',
  'unita',
  'docce',
  'degni',
  'maree',
  'marmi',
  'guide',
  'ladra',
  'fossi',
  'peste',
  'polso',
  'salmo',
  'evito',
  'zolla',
  'frodo',
  'volgo',
  'suino',
  'tempo',
  'dorsi',
  'stiva',
  'tolgo',
  'durai',
  'danzi',
  'sceme',
  'trono',
  'tarpi',
  'corse',
  'sputo',
  'laica',
  'spesa',
  'folte',
  'suore',
  'torbe',
  'vesso',
  'recai',
  'usato',
  'agiti',
  'getta',
  'rozze',
  'salvo',
  'elevo',
  'posai',
  'lesso',
  'grazi',
  'somma',
  'segua',
  'poppa',
  'certe',
  'mezzo',
  'mamme',
  'sigle',
  'diari',
  'perso',
  'liete',
  'isoli',
  'sozze',
  'daini',
  'reali',
  'seria',
  'ritte',
  'cieli',
  'elude',
  'danza',
  'sente',
  'poeta',
  'tonta',
  'raspo',
  'verde',
  'volpi',
  'usati',
  'cruda',
  'tenti',
  'pinte',
  'stati',
  'mazzi',
  'tazza',
  'stoni',
  'sposa',
  'vispe',
  'tonti',
  'muoio',
  'giogo',
  'culti',
  'entra',
  'adulo',
  'zaino',
  'unito',
  'matto',
  'salga',
  'arero',
  'ville',
  'gamba',
  'coste',
  'densa',
  'tanfo',
  'avrai',
  'colpa',
  'privi',
  'banda',
  'scuro',
  'tossi',
  'pigra',
  'darsi',
  'pezzi',
  'potea',
  'salpa',
  'aureo',
  'sante',
  'prova',
  'lessi',
  'vispi',
  'frane',
  'vieto',
  'sulla',
  'stelo',
  'cacci',
  'conci',
  'colai',
  'treni',
  'cuoca',
  'penai',
  'odore',
  'ansie',
  'bacia',
  'dille',
  'parti',
  'fusto',
  'molla',
  'zecca',
  'darle',
  'dirai',
  'fiumi',
  'tibie',
  'stura',
  'garze',
  'aerea',
  'sfamo',
  'vango',
  'tosai',
  'ferri',
  'usure',
  'passa',
  'fendi',
  'senta',
  'berci',
  'fasci',
  'ferie',
  'rotto',
  'freno',
  'anima',
  'salti',
  'mucca',
  'snodi',
  'tonno',
  'getto',
  'serva',
  'losco',
  'detto',
  'lenze',
  'manda',
  'viola',
  'tessa',
  'sfide',
  'merlo',
  'tersi',
  'volai',
  'bimbe',
  'lisca',
  'tassa',
  'grava',
  'olino',
  'sorsi',
  'gigli',
  'marce',
  'mesta',
  'tappo',
  'mazzo',
  'leghe',
  'sagge',
  'miele',
  'sudai',
  'baffi',
  'pensa',
  'usavi',
  'polpe',
  'tatuo',
  'finse',
  'cervi',
  'gusta',
  'bramo',
  'badai',
  'stare',
  'acuto',
  'legga',
  'lessa',
  'beate',
  'disto',
  'neghi',
  'pungo',
  'tosto',
  'denso',
  'scopo',
  'tempi',
  'breve',
  'buona',
  'rulla',
  'dalla',
  'tolti',
  'morta',
  'china',
  'brace',
  'brave',
  'scure',
  'sacra',
  'forma',
  'tengo',
  'aprii',
  'piana',
  'fallo',
  'latta',
  'fatta',
  'amera',
  'tinti',
  'zoppi',
  'slogo',
  'cotti',
  'iride',
  'domai',
  'altro',
  'arati',
  'beato',
  'spine',
  'udite',
  'toppa',
  'pesto',
  'oliva',
  'ricci',
  'soave',
  'brano',
  'belva',
  'fermi',
  'testi',
  'barra',
  'cotto',
  'zitte',
  'scema',
  'oziai',
  'fitte',
  'vendo',
  'esame',
  'cinta',
  'opere',
  'adira',
  'lenti',
  'lordo',
  'idoli',
  'rozza',
  'sacri',
  'sorde',
  'datai',
  'molte',
  'zuppa',
  'darmi',
  'fammi',
  'paesi',
  'campa',
  'gesti',
  'goffe',
  'lieta',
  'sonni',
  'sputi',
  'canna',
  'rione',
  'pasti',
  'leale',
  'rinvi',
  'sorci',
  'dinne',
  'curvo',
  'sogna',
  'datti',
  'lardo',
  'elica',
  'viste',
  'posso',
  'paste',
  'trema',
  'tasta',
  'torta',
  'sposi',
  'sbava',
  'finge',
  'prode',
  'beffe',
  'perse',
  'tuffo',
  'onore',
  'tondi',
  'ameni',
  'leali',
  'arino',
  'legni',
  'tutti',
  'linda',
  'tassi',
  'epuro',
  'preme',
  'cinto',
  'agito',
  'ritmo',
  'reggi',
  'forno',
  'univi',
  'tonde',
  'pance',
  'rende',
  'farmi',
  'orafo',
  'girai',
  'cremi',
  'vanti',
  'carne',
  'afose',
  'serro',
  'rissa',
  'mosso',
  'plebi',
  'milza',
  'siano',
  'trovi',
  'avidi',
  'emani',
  'pozzi',
  'farlo',
  'presi',
  'burli',
  'borgo',
  'cassa',
  'crepa',
  'calai',
  'curva',
  'bande',
  'dormi',
  'gomme',
  'errai',
  'duchi',
  'stana',
  'vendi',
  'vinci',
  'violi',
  'tomba',
  'lorda',
  'verme',
  'cinse',
  'rampa',
  'muore',
  'scusi',
  'valle',
  'stivi',
  'palco',
  'prose',
  'merce',
  'altri',
  'borse',
  'falso',
  'sedia',
  'lesta',
  'ladre',
  'sloga',
  'forme',
  'siate',
  'andra',
  'pendo',
  'gioia',
  'sedie',
  'ululi',
  'frati',
  'conte',
  'nonna',
  'gonfi',
  'crepo',
  'tersa',
  'creme',
  'aglio',
  'lievi',
  'balli',
  'eluse',
  'lanci',
  'sceso',
  'amari',
  'steli',
  'banca',
  'brina',
  'greco',
  'gravo',
  'pungi',
  'sgelo',
  'bacio',
  'climi',
  'crede',
  'dirmi',
  'farci',
  'amino',
  'studi',
  'avuto',
  'tuono',
  'cenai',
  'esile',
  'sviti',
  'dello',
  'travi',
  'tozzo',
  'cessa',
  'senti',
  'burle',
  'cieca',
  'cella',
  'punta',
  'trota',
  'bende',
  'speri',
  'collo',
  'libro',
  'false',
  'fugge',
  'sento',
  'pezza',
  'lieve',
  'fatte',
  'paese',
  'buffo',
  'copri',
  'trote',
  'sputa',
  'garza',
  'dogma',
  'celle',
  'lasci',
  'dillo',
  'serbi',
  'torne',
  'terse',
  'cigni',
  'odono',
  'aurei',
  'aiuto',
  'arava',
  'grana',
  'guaio',
  'piume',
  'prati',
  'manie',
  'abili',
  'lotte',
  'misti',
  'vento',
  'eredi',
  'negai',
  'cifre',
  'tinta',
  'dorso',
  'corti',
  'stona',
  'fieno',
  'grati',
  'animi',
  'culto',
  'terzo',
  'volle',
  'ladro',
  'tazze',
  'asine',
  'licei',
  'arata',
  'setta',
  'barca',
  'sonde',
  'nunzi',
  'fisso',
  'pista',
  'degli',
  'fughi',
  'farvi',
  'razzo',
  'ornai',
  'tizia',
  'curai',
  'pappe',
  'segni',
  'usavo',
  'sasso',
  'fiere',
  'pappa',
  'sodio',
  'guido',
  'acqua',
  'bocca',
  'copro',
  'suola',
  'usano',
  'ponte',
  'brami',
  'maggi',
  'prete',
  'lussi',
  'cavai',
  'abeti',
  'crema',
  'stima',
  'rango',
  'freni',
  'amore',
  'istmi',
  'bussi',
  'bulbo',
  'dirci',
  'onora',
  'retro',
  'telai',
  'cromo',
  'oppio',
  'grani',
  'bollo',
  'ratti',
  'linea',
  'dormo',
  'mangi',
  'cuore',
  'vispa',
  'osati',
  'unica',
  'gremi',
  'rosse',
  'cerca',
  'tatui',
  'matte',
  'fiuti',
  'steso',
  'soste',
  'ovali',
  'passo',
  'colti',
  'mista',
  'idolo',
  'spola',
  'cesso',
  'vanga',
  'scale',
  'froda',
  'ghisa',
  'creta',
  'pigri',
  'bersi',
  'tenie',
  'troni',
  'oneri',
  'daino',
  'leggo',
  'vinai',
  'svita',
  'clero',
  'fermo',
  'veste',
  'fitta',
  'brama',
  'stesi',
  'penso',
  'landa',
  'carro',
  'tolte',
  'parso',
  'tolga',
  'scesi',
  'cenni',
  'conta',
  'sotto',
  'cagne',
  'dirne',
  'degna',
  'stivo',
  'calza',
  'calma',
  'fieni',
  'bisce',
  'museo',
  'lento',
  'epici',
  'corda',
  'trame',
  'cesti',
  'fango',
  'curvi',
  'zitto',
  'figli',
  'mozzo',
  'afoso',
  'saggi',
  'piane',
  'alghe',
  'scafi',
  'stadi',
  'mappe',
  'buffi',
  'direi',
  'causo',
  'tizio',
  'copio',
  'quota',
  'gessi',
  'prima',
  'reato',
  'mance',
  'spiro',
  'premo',
  'lampi',
  'giovo',
  'dosai',
  'tedio',
  'talco',
  'tarda',
  'agile',
  'raggi',
  'servo',
  'basti',
  'siedi',
  'scopi',
  'santa',
  'svino',
  'pulsi',
  'dista',
  'siede',
  'stole',
  'fondo',
  'lieto',
  'isola',
  'piega',
  'marea',
  'dirle',
  'facce',
  'norma',
  'tetro',
  'massi',
  'farei',
  'berra',
  'odori',
  'eludi',
  'resti',
  'parve',
  'piace',
  'metti',
  'darti',
  'scuri',
  'grato',
  'mette',
  'basto',
  'corta',
  'smise',
  'colpi',
  'terga',
  'vende',
  'razzi',
  'goffa',
  'ronde',
  'prole',
  'orafi',
  'cedro',
  'giare',
  'narra',
  'piene',
  'vuota',
  'premi',
  'speci',
  'vetri',
  'aboli',
  'verga',
  'poste',
  'cicca',
  'bordi',
  'siedo',
  'aerei',
  'petti',
  'delle',
  'eludo',
  'duomi',
  'esimi',
  'monte',
  'avvia',
  'rotta',
  'desta',
  'ebrea',
  'corte',
  'tasso',
  'rossi',
  'latra',
  'bonta',
  'amava',
  'degno',
  'terne',
  'panno',
  'adito',
  'litri',
  'corri',
  'vasta',
  'penda',
  'ballo',
  'prese',
  'basta',
  'epoca',
  'lesse',
  'ragno',
  'monca',
  'rompa',
  'frasi',
  'dotte',
  'talpa',
  'patta',
  'lette',
  'stese',
  'fusti',
  'vezzo',
  'muffa',
  'lambi',
  'rasoi',
  'oblia',
  'porti',
  'multa',
  'serra',
  'sordi',
  'pesco',
  'croce',
  'piuma',
  'farle',
  'torno',
  'unico',
  'melme',
  'vibro',
  'canta',
  'verbi',
  'laghi',
  'capra',
  'ovvia',
  'falsa',
  'dagli',
  'versa',
  'marzo',
  'metro',
  'mozze',
  'serie',
  'sogno',
  'sturi',
  'risme',
  'dolce',
  'stimo',
  'lecco',
  'mezza',
  'sazia',
  'quale',
  'pregi',
  'fatto',
  'filai',
  'scolo',
  'vibra',
  'penna',
  'gioca',
  'opino',
  'rotti',
  'duomo',
  'mogli',
  'stata',
  'ferro',
  'dorme',
  'educa',
  'provo',
  'vermi',
  'spero',
  'bisca',
  'bruto',
  'raspa',
  'inizi',
  'colme',
  'svito',
  'beone',
  'lilla',
  'prego',
  'dissi',
  'molle',
  'nappa',
  'pesti',
  'nervi',
  'sieri',
  'credi',
  'rompe',
  'aspro',
  'riavo',
  'dirlo',
  'succo',
  'regno',
  'sfila',
  'frugo',
  'grave',
  'ninfa',
  'asola',
  'ampli',
  'umore',
  'tappa',
  'sassi',
  'piove',
  'avari',
  'rombi',
  'misto',
  'leone',
  'dogmi',
  'lenta',
  'gelso',
  'firmi',
  'vaghe',
  'vampi',
  'renda',
  'germi',
  'fante',
  'vessa',
  'perla',
  'grigi',
  'della',
  'arano',
  'legai',
  'matta',
  'tenni',
  'paura',
  'torti',
  'balzo',
  'certa',
  'sfami',
  'fissa',
  'umide',
  'passi',
  'porre',
  'stani',
  'tasti',
  'sarai',
  'buone',
  'gemma',
  'ranci',
  'spera',
  'falli',
  'treno',
  'manca',
  'avevi',
  'diede',
  'gambe',
  'svago',
  'corpi',
  'espio',
  'palla',
  'tolse',
  'vinco',
  'sette',
  'abusi',
  'serio',
  'fluii',
  'volge',
  'avere',
  'targa',
  'fatti',
  'crepe',
  'seppi',
  'vizio',
  'arera',
  'aduno',
  'verdi',
  'tonto',
  'pochi',
  'onoro',
  'lenza',
  'morse',
  'fette',
  'scoli',
  'muove',
  'polpa',
  'dette',
  'sopii',
  'mamma',
  'birra',
  'verra',
  'vista',
  'dente',
  'calco',
  'fosti',
  'morsa',
  'stola',
  'salve',
  'gobba',
  'dicci',
  'paghi',
  'ruolo',
  'fanno',
  'velai',
  'fiore',
  'scura',
  'canne',
  'muovo',
  'fuggi',
  'senno',
  'spese',
  'conia',
  'scali',
  'libra',
  'pizzo',
  'esiti',
  'nonne',
  'umido',
  'ovvio',
  'bassa',
  'solai',
  'segno',
  'batto',
  'romba',
  'vetto',
  'salva',
  'vello',
  'gente',
  'draga',
  'festa',
  'grate',
  'osano',
  'buffe',
  'falce',
  'zoppo',
  'osava',
  'erigi',
  'sorse',
  'altra',
  'padri',
  'fisse',
  'vince',
  'creai',
  'gioie',
  'scope',
  'fogna',
  'butti',
  'rauca',
  'bruno',
  'brani',
  'umile',
  'gergo',
  'trita',
  'quali',
  'fisco',
  'tanto',
  'punsi',
  'spazi',
  'calva',
  'amaca',
  'fugga',
  'salpi',
  'carta',
  'buche',
  'esime',
  'tosta',
  'sugli',
  'buono',
  'carri',
  'dirla',
  'forni',
  'crete',
  'sosti',
  'amico',
  'apici',
  'lavai',
  'scopa',
  'vasca',
  'diano',
  'epica',
  'croci',
  'gioco',
  'vieta',
  'ululo',
  'gridi',
  'magri',
  'molta',
  'barbe',
  'afone',
  'gusci',
  'leghi',
  'pulso',
  'lotto',
  'ricco',
  'copra',
  'buchi',
  'pagai',
  'letta',
  'vibri',
  'carni',
  'fingi',
  'bocce',
  'circo',
  'tenda',
  'gelsi',
  'ebete',
  'culla',
  'bugie',
  'avara',
  'lesti',
  'brune',
  'darvi',
  'cremo',
  'morso',
  'corvi',
  'acida',
  'perde',
  'scuso',
  'torre',
  'gambo',
  'sbavo',
  'sfili',
  'bauli',
  'firma',
  'bachi',
  'falla',
  'esche',
  'alzai',
  'notte',
  'causi',
  'lente',
  'cotta',
  'nozze',
  'sarte',
  'colle',
  'ronzi',
  'pigro',
  'turni',
  'zuffa',
  'sommo',
  'svisa',
  'disti',
  'prede',
  'reati',
  'globo',
  'emano',
  'bucce',
  'sedai',
  'bendo',
  'adori',
  'sunto',
  'crune',
  'caste',
  'tibia',
  'tento',
  'belve',
  'vitti',
  'archi',
  'odiai',
  'venni',
  'imiti',
  'bravo',
  'emula',
  'torri',
  'baffo',
  'curve',
  'prato',
  'spira',
  'mille',
  'birre',
  'sosta',
  'calda',
  'mondi',
  'ansia',
  'primi',
  'colpo',
  'arare',
  'bolla',
  'tigri',
  'monti',
  'reale',
  'masso',
  'sondo',
  'lorde',
  'pezzo',
  'serba',
  'golfo',
  'razza',
  'nuovi',
  'sondi',
  'evita',
  'salto',
  'piano',
  'sanai',
  'buffa',
  'sfogo',
  'palma',
  'mando',
  'sozzo',
  'siero',
  'stano',
  'pecca',
  'parlo',
  'obero',
  'cervo',
  'udito',
  'tanti',
  'bimbi',
  'dilla',
  'erige',
  'finii',
  'litro',
  'amaro',
  'raspi',
  'tuffi',
  'ebrei',
  'mezze',
  'sullo',
  'faggi',
  'corra',
  'sidro',
  'gonna',
  'lande',
  'roghi',
  'calzo',
  'scene',
  'forti',
  'porge',
  'facci',
  'osate',
  'avido',
  'oblio',
  'staro',
  'remai',
  'sorto',
  'miope',
  'terso',
  'riave',
  'guari',
  'caldi',
  'perno',
  'pigli',
  'berlo',
  'sazio',
  'atrio',
  'anche',
  'magra',
  'uscii',
  'scade',
  'sviai',
  'tozza',
  'vespa',
  'turba',
  'fende',
  'vinte',
  'colma',
  'narro',
  'piena',
  'sogni',
  'ritta',
  'vezzi',
  'pollo',
  'tondo',
  'pompo',
  'grane',
  'libri',
  'agita',
  'zampa',
  'situa',
  'sfida',
  'corsi',
  'tosti',
  'fogli',
  'rendi',
  'puzze',
  'cifra',
  'lindo',
  'bagna',
  'aceti',
  'purgo',
  'cruna',
  'funge',
  'ascia',
  'eviti',
  'fessa',
  'santi',
  'nuora',
  'svisi',
  'tutto',
  'rampe',
  'mania',
  'conii',
  'amena',
  'nuoti',
  'cuori',
  'dirti',
  'torto',
  'asilo',
  'butta',
  'fesso',
  'fondi',
  'zappi',
  'gusto',
  'morte',
  'genio',
  'dotai',
  'forzo',
  'renne',
  'sensi',
  'vetta',
  'lungo',
  'volti',
  'gallo',
  'tozze',
  'selci',
  'laico',
  'beffa',
  'tacci',
  'saldo',
  'prive',
  'orari',
  'visto',
  'tulle',
  'dense',
  'nuove',
  'belle',
  'afoni',
  'sarti',
  'entro',
  'grido',
  'dando',
  'attua',
  'scala',
  'turbo',
  'bello',
  'furba',
  'tinte',
  'predi',
  'addii',
  'appai',
  'bruco',
  'sieda',
  'orafe',
  'rosso',
  'solco',
  'gorgo',
  'quote',
  'lerci',
  'fiero',
  'dimmi',
  'balzi',
  'folti',
  'barre',
  'fanti',
  'udire',
  'scese',
  'gesso',
  'media',
  'cieco',
  'parco',
  'odino',
  'varie',
  'risma',
  'colmi',
  'tasto',
  'salmi',
  'zucca',
  'pausa',
  'fessi',
  'nullo',
  'pasta',
  'asino',
  'bocci',
  'podio',
  'salda',
  'pinna',
  'aditi',
  'udita',
  'aeree',
  'cauti',
  'verbo',
  'pelai',
  'denti',
  'salpo',
  'magro',
  'foche',
  'dacci',
  'messo',
  'tocco',
  'razze',
  'prega',
  'pezze',
  'lecca',
  'vuote',
  'soavi',
  'nonno',
  'masse',
  'ombre',
  'perle',
  'offro',
  'balie',
  'certo',
  'sonda',
  'terre',
  'tonfo',
  'cozzo',
  'ratto',
  'varco',
  'villa',
  'leggi',
  'pazzi',
  'corsa',
  'purga',
  'palle',
  'sozza',
  'muovi',
  'tenga',
  'casse',
  'sacco',
  'selve',
  'orali',
  'corpo',
  'torte',
  'santo',
  'vieni',
  'solca',
  'fughe',
  'benda',
  'gusti',
  'dieci',
  'lordi',
  'marco',
  'fendo',
  'mossi',
  'cosmo',
  'acuta',
  'trito',
  'celai',
  'serbo',
  'certi',
  'vallo',
  'creda',
  'sacro',
  'grafi',
  'madre',
  'tatto',
  'liceo',
  'adoro',
  'pizze',
  'mosci',
  'esuli',
  'zappa',
  'ronda',
  'rapii',
  'abbia',
  'regni',
  'gomma',
  'tenue',
  'getti',
  'umili',
  'poemi',
  'colla',
  'palpo',
  'marci',
  'lembo',
  'becco',
  'tenta',
  'babbo',
  'cuoco',
  'aspri',
  'negro',
  'mossa',
  'scadi',
  'credo',
  'bozze',
  'balia',
  'esito',
  'mense',
  'casti',
  'nuoto',
  'scavi',
  'tonni',
  'stono',
  'braci',
  'cetre',
  'epura',
  'dubbi',
  'danna',
  'trama',
  'darla',
  'oberi',
  'pulsa',
  'venti',
  'butto',
  'ressa',
  'pozzo',
  'teste',
  'ramai',
  'senza',
  'rospo',
  'tatua',
  'basso',
  'isolo',
  'fummo',
  'usura',
  'eleva',
  'manzi',
  'vanno',
  'manzo',
  'detti',
  'segue',
  'cacce',
  'crudi',
  'pazzo',
  'menti',
  'antro',
  'andro',
  'sappi',
  'panni',
  'ordii',
  'opina',
  'cullo',
  'zeppa',
  'forza',
  'fingo',
  'opaca',
  'botta',
  'servi',
  'salse',
  'firme',
  'golfi',
  'barai',
  'violo',
  'grado',
  'somme',
  'aspra',
  'ampio',
  'formi',
  'tonte',
  'cisti',
  'calme',
  'spago',
  'menta',
  'offra',
  'suoni',
  'serri',
  'zeppo',
  'rotte',
  'sesso',
  'scavo',
  'pesce',
  'lacca',
  'tetra',
  'culle',
  'monco',
  'muffe',
  'umane',
  'orale',
  'toghe',
  'rulli',
  'oblii',
  'tiene',
  'polli',
  'beoni',
  'spesi',
  'grafo',
  'bruna',
  'scemi',
  'liste',
  'vagli',
  'turno',
  'cauto',
  'osata',
  'soffi',
  'ardua',
  'edera',
  'cerco',
  'posto',
  'tetti',
  'forze',
  'esula',
  'mondo',
  'aceto',
  'nuova',
  'dazio',
  'lutto',
  'madri',
  'punti',
  'tarpo',
  'panca',
  'crepi',
  'corvo',
  'vessi',
  'tanta',
  'siamo',
  'degne',
  'nonni',
  'latro',
  'sfidi',
  'tizie',
  'dessi',
  'mieti',
  'furti',
  'burro',
  'rozzo',
  'furbi',
  'cadde',
  'eluso',
  'piedi',
  'ferme',
  'nulla',
  'narri',
  'chino',
  'sosto',
  'state',
  'vitto',
  'ovale',
  'seguo',
  'nafte',
  'umano',
  'rossa',
  'sfere',
  'mandi',
  'busto',
  'adula',
  'liana',
  'colse',
  'farli',
  'afono',
  'caddi',
  'calve',
  'testa',
  'ganci',
  'erigo',
  'russa',
  'regge',
  'gesto',
  'pugno',
  'musei',
  'zanna',
  'trami',
  'uditi',
  'tigre',
  'darei',
  'retto',
  'nella',
  'copre',
  'univo',
  'scova',
  'batta',
  'usino',
  'russi',
  'venne',
  'donna',
  'farne',
  'rughe',
  'amori',
  'ovile',
  'prosa',
  'scuse',
  'latri',
  'venga',
  'copie',
  'citai',
  'muova',
  'milze',
  'stiro',
  'aurea',
  'vuoti',
  'grata',
  'cenno',
  'negri',
  'scovi',
  'tetri',
  'potra',
  'multe',
  'preda',
  'disco',
  'frani',
  'auree',
  'gamma',
  'pinza',
  'lieti',
  'zolfo',
  'casco',
  'sessi',
  'densi',
  'sanno',
  'prodi',
  'palpa',
  'legge',
  'pizzi',
  'aduli',
  'snoda',
  'aiuti',
  'vaghi',
  'sviso',
  'avvii',
  'lecci',
  'merci',
  'notai',
  'arate',
  'pause',
  'toste',
  'calvi',
  'fissi',
  'cozzi',
  'atrii',
  'basai',
  'arato',
  'calli',
  'palme',
  'svaga',
  'notti',
  'radio',
  'vacca',
  'sopra',
  'piani',
  'ladri',
  'verte',
  'mesti',
  'corre',
  'quasi',
  'fieri',
  'unici',
  'parsa',
  'tagli',
  'offre',
  'regna',
  'patto',
  'mente',
  'adiri',
  'cedri',
  'tasse',
  'amara',
  'melma',
  'giuri',
  'invio',
  'ponti',
  'priva',
  'erano',
  'calde',
  'tonda',
  'aride',
  'tolto',
  'prove',
  'siepi',
  'feste',
  'quoti',
  'pasto',
  'fesse',
  'poppe',
  'plebe',
  'baule',
  'stile',
  'turai',
  'posti',
  'costi',
  'amavi',
  'sosia',
  'acide',
  'covai',
  'odoro',
  'scudi',
  'adiro',
  'visti',
  'omero',
  'nervo',
  'visse',
  'volpe',
  'pesca',
  'ampie',
  'zeppi',
  'volgi',
  'avete',
  'pelli',
  'danze',
  'aereo',
  'tatti',
  'rauco',
  'opera',
  'frase',
  'tombe',
  'gambi',
  'vespe',
  'tergi',
  'parto',
  'usare',
  'viole',
  'scalo',
  'sfilo',
  'occhi',
  'aduna',
  'fogne',
  'operi',
  'desto',
  'tergo',
  'bimbo',
  'bandi',
  'guida',
  'ebree',
  'cetra',
  'unire',
  'sacca',
  'ritti',
  'acidi',
  'zanne',
  'sobri',
  'termi',
  'marmo',
  'porto',
  'salai',
  'donai',
  'pompe',
  'viene',
  'colpe',
  'vanto',
  'cotte',
  'farsi',
  'porte',
  'punte',
  'tette',
  'righe',
  'sparo',
  'condi',
  'stimi',
  'darci',
  'piaga',
  'beati',
  'letto',
  'gemme',
  'tolsi',
  'entri',
  'suora',
  'possa',
  'farla',
  'udiva',
  'ovili',
  'canti',
  'corro',
  'osare',
  'abiti',
  'bimba',
  'vasti',
  'chini',
  'spada',
  'bugia',
  'omeri',
  'vette',
  'zampe',
  'segna',
  'metto',
  'mappa',
  'puzza',
  'galli',
  'toppe',
  'ciclo',
  'imito',
  'spina',
  'fumai',
  'pendi',
  'menai',
  'serre',
  'socio',
  'umani',
  'spade',
  'persi',
  'edere',
  'cozze',
  'terme',
  'ameno',
  'orlai',
  'voghi',
  'porro',
  'possi',
  'grano',
  'acuti',
  'borie',
  'dammi',
  'padre',
  'frana',
  'utile',
  'balze',
  'nutre',
  'goffi',
  'forzi',
  'fugai',
  'suona',
  'asili',
  'mensa',
  'seggi',
  'apice',
  'predo',
  'poche',
  'orafa',
  'foste',
  'ozino',
  'elevi',
  'amici',
  'trova',
  'torni',
  'tocca',
  'renna',
  'libre',
  'boria',
  'desti',
  'valga',
  'cento',
  'tozzi',
  'mezzi',
  'dotta',
  'caldo',
  'snodo',
  'sozzi',
  'bella',
  'cosmi',
  'callo',
  'colto',
  'annoi',
  'russo',
  'formo',
  'afosa',
  'trovo',
  'stime',
  'aspre',
  'sommi',
  'venda',
  'largo',
  'aroma',
  'viale',
  'lepre',
  'umida',
  'burla',
  'frano',
  'legna',
  'finto',
  'gonne',
  'aveva',
  'ninfe',
  'varia',
  'pecco',
  'ritmi',
  'donne',
  'selle',
  'lusso',
  'educo',
  'firmo',
  'porse',
  'dallo',
  'pesai',
  'crudo',
  'emulo',
  'scena',
  'piste',
  'caute',
  'bombe',
  'clima',
  'resta',
  'situo',
  'salvi',
  'pelle',
  'smisi',
  'conto',
  'fitto',
  'cambi',
  'fuori',
  'prime',
  'nuche',
  'petto',
  'prema',
  'nuore',
  'salde',
  'turri',
  'marca',
  'sorti',
  'leoni',
  'sella',
  'tessi',
  'bendi',
  'fanne',
  'giura',
  'dorma',
  'sordo',
  'scesa',
  'lance',
  'micce',
  'fuoco',
  'valgo',
  'lotti',
  'usata',
  'bruci',
  'invii',
  'vispo',
  'gobbi',
  'cuoio',
  'stavi',
  'acque',
  'ruoli',
  'goffo',
  'sfoci',
  'guado',
  'pozze',
  'zoppe',
  'tesso',
  'verso',
  'seghe',
  'fagli',
  'costo',
  'ronzo',
  'adagi',
  'mirai',
  'avrei',
  'ruppe',
  'iodio',
  'gravi',
  'ebreo',
  'torba',
  'folle',
  'talpe',
  'porlo',
  'secca',
  'pegno',
  'negra',
  'triti',
  'bolle',
  'unite',
  'cagna',
  'emuli',
  'cause',
  'nevai',
  'vinti',
  'ricca',
  'antri',
  'armai',
  'stufa',
  'astri',
  'puzzi',
  'tardo',
  'ardui',
  'osavi',
  'diamo',
  'monto',
  'rioni',
  'pompa',
  'nasco',
  'dieta',
  'forte',
  'scava',
  'amavo',
  'lepri',
  'morda',
  'corna',
  'salma',
  'espia',
  'perni',
  'altre',
  'lunga',
  'vinto',
  'colta',
  'tanga',
]
